import { f7 } from "framework7-react";
import { db } from "../js/db";
import Dexie from "dexie";
import { post } from "../axios/axiosClient";

const refetchChatMessages = () => {
	(async () => {
		let res = await post("/chat/get_chat_messages", {
			type: "application/x-www-form-urlencoded",
			data: {
				chat_id: f7.store.state.activeConversation,
				from_search: 0,
				offset: 0,
				swipe_up: 0,
			},
		});

		if (res?.data?.data && res.data.data.chats) {
			res.data.data.chats.reverse();
			db.messages
				.bulkPut(res?.data?.data?.chats)
				.then(async function (lastKey) {
				})
				.catch(Dexie.BulkError, function (err) {
					state.isLoading = false;
				});
		}
	})();
};

const onConnect = () => {
    f7.store.dispatch("setSocketConnection", true);
	if (
		f7.views.main?.router.currentRoute.url.includes("chats") &&
		f7.store.state.activeConversation !== "null"
	) {
		refetchChatMessages();
	}
};

export const onDisconnect = () => {
	f7.store.dispatch("setSocketConnection", false);
};

const onConnectError = (error) => {
	f7.store.dispatch("setSocketConnection", false);
};

const onError = (error) => {
	f7.store.dispatch("setSocketConnection", false);
};

const onReconnect = (number) => {
    f7.store.dispatch("setSocketConnection", true);
	if (
		f7.views.main.router.currentRoute.url.includes("chats") &&
		f7.store.state.activeConversation !== "null"
	) {
		refetchChatMessages();
	}
};

const onReconnecting = (number) => {
};

const onReconnectFailed = () => {
	f7.store.dispatch("setSocketConnection", false);
};

const onReconnectError = (error) => {
	f7.store.dispatch("setSocketConnection", false);
};

export const bindSystemEvents = (socket) => {
	socket.on("connect", onConnect);
	socket.on("disconnect", onDisconnect);
	socket.on("connect_error", onConnectError);
	socket.on("error", onError);
	socket.on("reconnect", onReconnect);
	socket.on("reconnecting", onReconnecting);
	socket.on("reconnect_failed", onReconnectFailed);
	socket.on("reconnect_error", onReconnectError);
	socket.on("shutdown", (data) => {
		socket.disconnect();
		setTimeout(() => {
			socket.connect();
		}, 1000);
	});
};
export const unbindSystemEvents = (socket) => {
	socket.off("connect", onConnect);
	socket.off("disconnect", onDisconnect);
	socket.off("connect_error", onConnectError);
	socket.off("error", onError);
	socket.off("reconnect", onReconnect);
	socket.off("reconnecting", onReconnecting);
	socket.off("reconnect_failed", onReconnectFailed);
	socket.off("reconnect_error", onReconnectError);
};
