import React from "react";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { f7 } from "framework7-react";

function Googlelogin({ onSignIn }) {
	function handleCallbackResponse(response) {
		if (response) {
			try {
				var userObject = jwtDecode(response.credential);
				var logindata = {
					fristname: userObject.name,
					email: userObject.email,
					profile_picture: userObject.picture,
					type: "google",
					token: userObject.sub,
					device_type: "web",
				};
				onSignIn(logindata);
			} catch (error) {
				console.error("Error decoding JWT token or processing response", error);
			}
		}
	}

	return (
		<GoogleLogin
			theme="outline"
			size="medium"
			type="icon"
			shape="circle"
			width="38px"
			logo_alignment="center"
			onSuccess={handleCallbackResponse}
			onError={() => {
				f7.toast.create({ text: "Login Failed" }).open();
			}}
		/>
	);
}

export default Googlelogin;
