import { Page, Panel, Tabs, f7, useStore, Popup } from "framework7-react";
import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect, useWindowSize } from "react-use";
import Menubar from "../../components/navs/menubar";
import CallScreen from "../../components/call/callscreen";
import InComingCall from "../../components/call/InComingCall";

import { AUTHTOKEN_COOKIE } from "../../constants";
import { conversationService } from "../../redux/features/chatSlice/conversation";
import { userContactsService } from "../../redux/features/userSlice/contacts";
import { deleteCookie } from "../../utils/functions";
import NewChat from "./panels/new-chat";
import "./style.css";
import {
	Chat,
	Profile,
	Settings,
	SettingNotification,
	Stories,
	PrivacySetting,
	Language,
	BlockedContact,
	LoggedDevices,
} from "./tabs";
import Notifications from "./panels/notifications";

import { persistor } from "../../redux/store";
import { db } from "../../js/db";
import { userProfileService } from "../../redux/features/userSlice/profile";
import ContactInfo from "./panels/contact-info";
import { pendingRequestsService } from "../../redux/features/userSlice/pendingRequests";
import { mentionsService } from "../../redux/features/userSlice/mentions";
import { checkNotificationPermission } from "../../utils/permission.js";

const ChatsPage = ({ f7router }) => {
	const { width } = useWindowSize();
	const desktop = useMemo(() => width >= 775, [width]);
	const dispatch = useDispatch();
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const socketStatus = useStore("socketConnected");
	const socketIteration = useStore("socketIteration");
	const [activeView, setActiveView] = useState("chats");
	const [newChatOpened, setNewChatOpened] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const selectedUserId = useStore("selectedUserId");
	const [permissionQueried, setPermissionQueried] = useState(false);

	const callChatId = useStore("callChatId");
	const inCommingCall = useStore("inCommingCall");

	const callType = useStore("callType");
	const callDirection = useStore("callDirection");

	const handlePermissionOnClick = () => {
		console.log("clicked");
		checkNotificationPermission();
		setPermissionQueried(true);
	};

	const onInit = () => {
		dispatch(
			userProfileService({
				user_id: loginResponse?.data?.id,
			})
		);
		dispatch(conversationService({}));
		dispatch(userContactsService({}));
		dispatch(pendingRequestsService({ user_id: loginResponse.data.id }));
		dispatch(mentionsService({}));
		if (
			f7router?.history?.length > 0 &&
			f7router?.history?.includes("/login/")
		) {
			checkNotificationPermission();
			setPermissionQueried(true);
		} else if (!permissionQueried) {
			document.addEventListener("click", handlePermissionOnClick);
		}
	};

	useEffect(() => {
		if (permissionQueried)
			document.removeEventListener("click", handlePermissionOnClick);
		
		return () => {
			document.removeEventListener("click", handlePermissionOnClick);
		};
	}, [permissionQueried]);

	const { allConversationsResponse, isLoading: isLoadingConversation } =
		useSelector((state) => state.conversationSlice);

	useUpdateEffect(() => {
		if (allConversationsResponse?.code === 401) {
			deleteCookie(AUTHTOKEN_COOKIE);
			persistor.purge();
			db.delete()
				.then(() => {})
				.catch((err) => {
					console.error("Could not delete database");
				})
				.finally(() => {
					f7router.navigate("/login/", { reloadAll: true });
				});
		}
	}, [allConversationsResponse]);

	useUpdateEffect(() => {
		if (socketStatus && socketIteration > 1) {
			dispatch(conversationService({}));
		}
	}, [socketStatus, socketIteration]);

	useUpdateEffect(() => {
		if (desktop && f7router.currentRoute.path === "/chats")
			f7router.navigate(`/chats/null/single`);
		else if (!desktop && f7router.currentRoute.path === "/chats/null/single") {
			f7router.navigate(`/chats`);
		}
	}, [desktop]);

	const toggleNewChatState = (e) => {
		try {
			e.preventDefault();
			e.stopPropagation();
		} catch {}

		setNewChatOpened(!newChatOpened);
	};

	const activeViewChanged = (view) => {
		setActiveView(view);

		if (!desktop || activeView === view) return;

		switch (view) {
			case "chats":
				!desktop
					? f7.views.main.router.navigate("/chats/")
					: f7.views.main.router.navigate("/chats/null/single");
				break;
			case "stories":
				!desktop
					? f7router.navigate(`/stories`)
					: f7router.navigate(`/stories/:null`);
				break;
		}
	};

	return (
		<Page
			id="chats"
			name="chats"
			noSwipeback
			pageContent={false}
			onPageInit={onInit}>
			<Menubar
				position={desktop ? "left" : "bottom"}
				active={activeView}
				toggleNewChatState={toggleNewChatState}
				stateChaged={activeViewChanged}
			/>

			<Tabs id="chats_tabs" animated>
				<Chat
					f7router={f7router}
					toggleNewChatState={toggleNewChatState}
					stateChaged={activeViewChanged}
				/>
				<Stories f7router={f7router} stateChaged={activeViewChanged} />
				<Profile />
				<Settings />
				<SettingNotification />
				<LoggedDevices />
				<PrivacySetting />
				<Language />
				<BlockedContact />
				<Notifications f7router={f7router} />
				<NewChat
					f7router={f7router}
					opened={newChatOpened}
					onPanelClosed={() => setNewChatOpened(false)}
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					closeNewChat={() => setNewChatOpened(false)}
				/>
			</Tabs>
			<Panel
				id="userinfoPanel"
				opened={selectedUserId || false}
				onPanelClosed={() => f7.store.dispatch("setSelectedUserId", null)}
				containerEl=".singleChatPage"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{selectedUserId && (
					<ContactInfo
						user_id={selectedUserId}
						userMode
						onConnectClick={() => {
							sendRequest(selectedUserId);
						}}
					/>
				)}
			</Panel>
			<Popup
				tabletFullscreen
				className="callscreen-popup"
				backdrop={false}
				closeByBackdropClick={false}
				onPopupOpened={() => {}}
				containerEl=".singleChatPage">
				{callChatId > 0 && (
					<CallScreen
						chatId={callChatId}
						mode={callType}
						direction={callDirection}
					/>
				)}
			</Popup>
			<InComingCall callData={inCommingCall} />
		</Page>
	);
};

export default ChatsPage;
