import React from "react";
import { Page, PageContent, Block, Button } from "framework7-react";
import { useTranslation } from "react-i18next";
import AuthNavbar from "../../components/navs/authNavbar";
import kt_logo from "../../assets/images/placeholders/kt_logo.svg";
import stock from "../../assets/images/stock/splash_image.svg";
import "./style.css";
import { APPLE_SDK } from "../../constants";

const SpalshPage = () => {
	const { t } = useTranslation();

	return (
		<Page
			name="spalsh"
			pageContent={false}
			onPageInit={() => {
				window.AppleID.auth.init(APPLE_SDK);
			}}>
			<AuthNavbar />

			<PageContent className="overflow-hidden main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center h-[-webkit-fill-available]">
						<img src={kt_logo} className="max-w-[158px] mb-[48px]" />
						<span className="text-[#263238] text-xl text-center font-gelar">
							{t("communicate better")}
						</span>
						<Button
							href="/signup/"
							text={t("Register")}
							rounded
							fill
							className="w-[380px] md:w-[400px] h-[62px] p-[20px] font-semibold my-[10px]"></Button>
						<Button
							href="/login/"
							text={t("Login")}
							rounded
							outline
							className="w-[380px] md:w-[400px] h-[62px] p-[20px] font-semibold my-[10px] outline-theme"></Button>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center h-[-webkit-fill-available]">
						<img src={stock} />
					</div>
				</Block>
			</PageContent>
		</Page>
	);
};
export default SpalshPage;
