import { createContext } from "react";
import io from "socket.io-client";
import { SOCKET_URL, SOCKET_PUBLIC_URL } from "../constants";

export const socketInit = async (token) => {
	return !token
		? io(SOCKET_PUBLIC_URL, {
				query: {
					isEncrypted: false,
				},
				transports: ["websocket"],
				forceNew: true,
				autoConnect: true,
		  })
		: io(SOCKET_URL, {
				query: {
					isEncrypted: false,
					...(token && { token: token }),
				},
				transports: ["websocket"],
				forceNew: true,
				autoConnect: true,
		  });
};

export const SocketContext = createContext();
