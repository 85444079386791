import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	editGroupResponse: {},
	isLoading: false,
	error: false,
};

export const editGroupSlice = createSlice({
	name: "editGroup",
	initialState,
	reducers: {
		saveEditGroupResponse: (state, action) => {
			state.editGroupResponse = action.payload;
		},
		resetEditGroupResponse: (state) => {
			state.editGroupResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(editGroupService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(editGroupService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.editGroupResponse = action.payload;
			})
			.addCase(editGroupService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const editGroupService = createAsyncThunk("chat/editGroup", async (values) => {
	if (!values) return;

	return post("/chat/edit_group", {
		type: "application/json",
		data: { encodedParams: await encrypt(JSON.stringify(values)) },
	});
});

export const { saveEditGroupResponse, resetEditGroupResponse } =
	editGroupSlice.actions;
export { editGroupService };

export default editGroupSlice.reducer;
