import React, { useMemo, useState } from "react";
import { isNumber } from "../../../utils/functions";

const LazyLoaderWraper = ({
	src,
	placeholder,
	height,
	width,
	alt,
	className,
	wrapperclassname,
	onClick,
}) => {
	const srcMemo = useMemo(() => src, [src]);
	const placeholderSrcMemo = useMemo(
		() => (placeholder ? placeholder : null),
		[placeholder]
	);
	const heightMemo = useMemo(() => (height ? height : "auto"), [height]);
	const widthMemo = useMemo(() => (width ? width : "auto"), [width]);
	const altMemo = useMemo(() => alt || "", [alt]);
	const classNameMemo = useMemo(() => className || "", [className]);
	const wrapperClassNameMemo = useMemo(
		() => wrapperclassname || "",
		[wrapperclassname]
	);
	const [imageError, setImageError] = useState(false);

	const style = useMemo(() => {
		return {
			backgroundImage: `url(${placeholderSrcMemo})`,
			backgroundPosition: `center`,
			backgroundSize: `cover`,
			backgroundRepeat: `no-repeat`,
			height: `${isNumber(heightMemo) ? heightMemo + "px" : heightMemo}`,
			width: `${isNumber(widthMemo) ? widthMemo + "px" : widthMemo}`,
		};
	}, [placeholder, heightMemo, widthMemo]);

	return (
		<>
			{!imageError ? (
				<img
					src={srcMemo}
					alt={altMemo}
					className={classNameMemo}
					height={heightMemo}
					width={widthMemo}
					loading="lazy"
					onClick={onClick}
					style={style}
					onError={() => setImageError(true)}
				/>
			) : (
				<img
					src={placeholderSrcMemo}
					alt={altMemo}
					className={classNameMemo}
					height={heightMemo}
					width={widthMemo}
					loading="lazy"
					onClick={onClick}
					style={style}
				/>
			)}
		</>
		// <LazyLoadImage
		// 	src={srcMemo}
		// 	placeholder={placeholderSrcMemo}
		// 	height={heightMemo}
		// 	width={widthMemo}
		// 	alt={altMemo}
		// 	className={classNameMemo}
		// 	wrapperclassname={wrapperClassNameMemo}
		// />
	);
};

export default LazyLoaderWraper;
