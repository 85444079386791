import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";
import { db } from "../../../js/db";
import Dexie from "dexie";

const initialState = {
	mentionsResponse: {},
	isLoading: false,
	error: false,
};

export const mentionsSlice = createSlice({
	name: "mentions",
	initialState,
	reducers: {
		saveMentionsResponse: (state, action) => {
			state.mentionsResponse = action.payload;
		},
		resetMentionsResponse: (state) => {
			state.mentionsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(mentionsService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(mentionsService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action?.payload?.data) return;
				state.mentionsResponse = action.payload;
				db.mentions
					.bulkPut(action?.payload?.data)
					.then(async function (lastKey) {
					})
					.catch(Dexie.BulkError, function (err) {
					});
			})
			.addCase(mentionsService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const mentionsService = createAsyncThunk(
	"user/mentions",
	async (values) => {
		if(!values) return;

		return post("user/get_notifications", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveMentionsResponse, resetMentionsResponse } =
	mentionsSlice.actions;
export { mentionsService };

export default mentionsSlice.reducer;
