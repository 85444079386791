import { Block, List, ListGroup, f7 } from "framework7-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStoriesById } from "../../redux/features/storiesSlice/currentStories";
import { updateStoryType } from "../../redux/features/storiesSlice/userStory";
import { calculateLastseen } from "../../utils/functions";
import { addStoryIcon } from "../../utils/icons";
import Story from "./Story";
import useStory from "../../hooks/useStory";
import { useTranslation } from "react-i18next";

function TabContent({ translate }) {
	const { userRecentStories, userViewedStories } = useStory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const userStories = useSelector((store) => store?.stories?.stories);
	const profile = useSelector(
		(store) => store?.loginSlice?.loginResponse?.data
	);
	const showStories = ({ userId, stories }) => {
		dispatch(updateStoriesById({ userId, stories }));
	};

	return (
		<Block className="px-[1rem] py-[0.25rem] w-[100%] flex flex-col gap-[0.25rem] overflow-y-scroll h-[100%] pb-[5rem]">
			{/* Create Story Sec */}
			<List className="storyParent" mediaList>
				<ListGroup>
					<Story title={translate("YourStory")} groupTitle />
					<Story
						avatar={addStoryIcon}
						title={translate("createStoryTitle")}
						subTitle={translate("createStoryDesc")}
						onClick={() => {
							f7.popover.close();
							dispatch(updateStoryType("none"));
							dispatch(updateStoriesById({}));
						}}
					/>{" "}
					<Story
						avatar={profile?.profile_image}
						title={t("My Story")}
						onClick={() => {
							f7.popover.close();
							setTimeout(() => {
								showStories({ userId: profile?.id, stories: userStories });
							}, 200);
						}}
					/>{" "}
				</ListGroup>
			</List>
			{/* User Stories Sec */}
			<List className="storyParent flex flex-col gap-[0.5rem]" mediaList>
				{/* Recent Stories */}
				{userRecentStories?.length > 0 && (
					<ListGroup>
						<Story title={translate("RecentStories")} groupTitle />
						{userRecentStories?.map((user, index) => {
							return (
								<Story
									isSpecial={user.isSpecial}
									isViewAble={user.isViewAble}
									key={index}
									avatar={user?.profile_image}
									title={user?.nickname || user?.firstname}
									subTitle={calculateLastseen(
										new Date(user?.unix_timestamp * 1000)
									)}
									onClick={() => {
										f7.popover.close();
										showStories({});
										setTimeout(() => {
											showStories({
												userId: user?.user_id,
												stories: userStories,
											});
										}, 150);
									}}
								/>
							);
						})}
					</ListGroup>
				)}
				{/* Viewed Stories */}
				{userViewedStories?.length > 0 && (
					<ListGroup>
						<Story title={translate("ViewedStories")} groupTitle />
						{userViewedStories.map((user, index) => {
							return (
								<Story
									isViewAble={false}
									key={index}
									avatar={user?.profile_image}
									title={user?.nickname || user?.firstname}
									subTitle={calculateLastseen(
										new Date(user?.unix_timestamp * 1000)
									)}
									onClick={() => {
										f7.popover.close();
										showStories({});
										setTimeout(() => {
											showStories({
												userId: user?.user_id,
												stories: userStories,
											});
										}, 150);
									}}
								/>
							);
						})}
					</ListGroup>
				)}
			</List>
		</Block>
	);
}

export default TabContent;
