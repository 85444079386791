import React, { useMemo, useState } from "react";
import {  Link, Toolbar, f7 } from "framework7-react";
import logo from "../../../assets/images/logo/Kalamtime.svg";
import "./style.css";
import Devider from "../../misc/devider";
import { useWindowSize } from "react-use";

const Menubar = ({
	position,
	stateChaged,
	active,
}) => {
	const { width, height } = useWindowSize();
	const desktop = useMemo(() => width >= 775, [width]);

	const IconWrapper = ({ children }) => {
		return desktop ? (
			<div className="icon-container w-full overflow-y-auto overflow-x-hidden flex flex-col items-center justify-start gap-[16px] flex-[1_0%_0%]">
				{children}
			</div>
		) : (
			children
		);
	};

	return (
		<Toolbar
			tabbar
			scrollable={!desktop}
			bottom={!position}
			outline
			className={`${position && "toolbar-" + position} z-[999]`}>
			{desktop && (
				<>
					<div className="logo flex justify-center items-center w-full">
						<img src={logo} className="w-[55px]" />
					</div>
					<Devider />
				</>
			)}
			<IconWrapper>
				{/* <Link
					tabLink="#reels"
					tabLinkActive={active === "reels"}
					onClick={() => {
						stateChaged("reels");
					}}>
					<i className="kt-tb reels" />
				</Link> */}
				<Link
					tabLink="#chatTab"
					tabLinkActive={active === "chats"}
					onClick={() => {
						stateChaged("chats");
					}}>
					<i className="kt-tb chat" />
				</Link>
				<Link
					tabLink="#storiesTab"
					tabLinkActive={active === "stories"}
					onClick={() => {
						stateChaged("stories");
					}}>
					<i className="kt-tb discover" />
				</Link>
				{/* <Link
					tabLink="#community"
					tabLinkActive={active === "community"}
					onClick={() => {
						stateChaged("community");
					}}>
					<i className="kt-tb community" />
				</Link>
				<Link
					tabLink="#news"
					tabLinkActive={active === "news"}
					onClick={() => {
						stateChaged("news");
					}}>
					<i className="kt-tb news" />
				</Link> */}
			</IconWrapper>
			{/* <Devider /> */}
			{/* <Button
				type="button"
				onClick={toggleNewChatState}
				tabLink='#contacts'
				className="link-bottom">
				<i className="kt-tb newchat" />
			</Button> */}
		</Toolbar>
	);
};

export default Menubar;
