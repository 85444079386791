import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";

const initialState = {
	pwdResetToken: null,
	restOTP: {},
	createPwdToken: null,
	isLoading: false,
	error: null,
	FRGTEmailPWD: null,
};

const pwdResetTokenSlice = createSlice({
	name: "pwdResetToken",
	initialState,
	reducers: {
		savePasswordResetTokenResponse: (state, action) => {
			state.pwdResetToken = action.payload;
		},
		resetPasswordResetTokenResponse: (state) => {
			state.pwdResetToken = null;
		},
		savePasswordOTPTokenResponse: (state, action) => {
			state.restOTP = action.payload;
		},
		resetPasswordOTPTokenResponse: (state) => {
			state.restOTP = null;
		},
		saveResetPasswordResponse: (state, action) => {
			state.createPwdToken = action.payload;
		},
		resetResetPasswordResponse: (state) => {
			state.createPwdToken = null;
		},
		savePwdThroughEmailResponse: (state, action) => {
			state.FRGTEmailPWD = action.payload;
		},
		resetPwdThroughEmailResponse: (state) => {
			state.FRGTEmailPWD = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(requestPasswordResetTokenService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(
				requestPasswordResetTokenService.fulfilled,
				(state, action) => {
					state.error = null;
					state.isLoading = false;
					if (!action.payload) return;
					state.pwdResetToken = action.payload;
				}
			)
			.addCase(
				requestPasswordResetTokenService.rejected,
				(state, action) => {
					state.error =
						action.error?.message ||
						"An Error Occured While Making The Token Request";
					state.isLoading = false;
				}
			)

			.addCase(verifyPasswordOTPTokenService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(
				verifyPasswordOTPTokenService.fulfilled,
				(state, action) => {
					state.error = null;
					state.isLoading = false;
					if (!action.payload) return;
					state.restOTP = action.payload;
				}
			)
			.addCase(
				verifyPasswordOTPTokenService.rejected,
				(state, action) => {
					state.error =
						action.error?.message ||
						"An Error Occured While Verify OTP";
					state.isLoading = false;
				}
			)

			.addCase(createPasswordService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(createPasswordService.fulfilled, (state, action) => {
				state.error = null;
				state.isLoading = false;
				if (!action.payload) return;
				state.createPwdToken = action.payload;
			})

			.addCase(createPasswordService.rejected, (state, action) => {
				state.error =
					action.error?.message ||
					"An Error Occured While Making The Token Request";
				state.isLoading = false;
			})
			.addCase(forgotPwdThroughEmailService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(
				forgotPwdThroughEmailService.fulfilled,
				(state, action) => {
					state.error = null;
					state.isLoading = false;
					if (!action.payload) return;
					state.FRGTEmailPWD = action.payload;
				}
			)
			.addCase(forgotPwdThroughEmailService.rejected, (state, action) => {
				state.error =
					action.error?.message ||
					"An Error Occured While Sending Email";
				state.isLoading = false;
			});
	},
});

//forgotPasswordPush  api service params countary_code,phoneNumber

const requestPasswordResetTokenService = createAsyncThunk(
	"passwordResetSlice/requestPasswordResetTokenService",
	async (values) => {
		if (!values) return;
		
		return post("/user/forgotPasswordPush", {
			type: "application/x-www-form-urlencoded",
			data: values,
		});
	}
);

//verifyPasswordOTP  api service params OTP onyour email ar push notifaction  password_reset_token (coming from the forgotPasswordPush )
const verifyPasswordOTPTokenService = createAsyncThunk(
	"passwordResetSlice/verifyPasswordOTPTokenService",
	async (values) => {
		if (!values) return;
		
		return post("/user/verifyPasswordOTP", {
			data: values,
		});
	}
);

// Creaet password api service params password , comfirm password password_reset_token (coming from the verifyPasswordOTP )
const createPasswordService = createAsyncThunk(
	"passwordResetSlice/createPasswordService",
	async (values) => {
		if (!values) return;
		
		return post("/user/resetPassword/", {
			data: values,
		});
	}
);

// Forgot Password Service Through Email  api service params Email
const forgotPwdThroughEmailService = createAsyncThunk(
	"passwordResetSlice/forgotPwdThroughEmailService",
	async (values) => {
		if (!values) return;
		
		return post("/user/forgotPasswordEmail/", {
			type: "application/x-www-form-urlencoded",
			data: values,
		});
	}
);

//write more services like password reset and otp verification here
// that way the token can be reused

export const {
	savePasswordResetTokenResponse,
	resetPasswordResetTokenResponse,
	savePasswordOTPTokenResponse,
	resetPasswordOTPTokenResponse,
	saveResetPasswordResponse,
	resetResetPasswordResponse,
	savePwdThroughEmailResponse,
	resetPwdThroughEmailResponse,
} = pwdResetTokenSlice.actions;
export {
	requestPasswordResetTokenService,
	verifyPasswordOTPTokenService,
	createPasswordService,
	forgotPwdThroughEmailService,
}; //export more services

export default pwdResetTokenSlice.reducer;
