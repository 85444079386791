import { Block, Button, List, ListGroup, f7, useStore } from "framework7-react";
import { Setting2 } from "iconsax-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  postStory,
  updateIspostingStatus,
} from "../../redux/features/storiesSlice";
import { updateStoryText } from "../../redux/features/storiesSlice/userStory";
import Story from "./Story";
import TabHeader from "./TabHeader";
import TextStoryFeatures from "./feathers/TextStoryFeatures";
import VideoStoryTabs from "./feathers/VideoStoryTabs";

function EditStoryTab({
  translate,
  postStoryHandler: postFileHandler,
  defaultStorySetting,
}) {
  const dispatch = useDispatch();
  const storyData = useSelector((store) => store?.userStory);
  const profile = useSelector(
    (store) => store?.loginSlice?.loginResponse?.data
  );
  const isPosting = useSelector((store) => store?.stories?.isPosting);
  const storyFile = useStore("storyFile");

  const postStoryHandler = () => {
    if (storyData?.type === "text") {
      if (storyData?.text && storyData?.text.length > 0) {
        dispatch(updateIspostingStatus(true));
        try {
          f7.toast
            .create({
              text: "Sending...",
              position: "top",
              horizontalPosition: "right",
              closeTimeout: "18000",
            })
            .open();
          dispatch(
            postStory({
              message: storyData?.text || "",
              type: "text",
              identifier: uuidv4(),
              color: storyData.bg,
            })
          );
        } catch (error) {
        }
      } else {
        f7.toast.create({ text: "Invalid Input" }).open();
      }
    } else if (storyData.type === "file" && storyFile) {
			dispatch(updateIspostingStatus(true));

			postFileHandler(storyFile);
		} else {
		}
  };
  useEffect(() => {
    if (!isPosting) {
      defaultStorySetting();
    }
  }, [isPosting]);

  useEffect(() => {
    dispatch(updateStoryText(""));
  }, []);

  return (
    <Block className="h-[100%] flex flex-col flex-1 overflow-scroll">
      <TabHeader
        onClose={() => defaultStorySetting()}
        disabled={isPosting}
        translate={translate}
        tablink="#userStoriesTab"
      />
      <List
        className="storyParent pt-[1rem] px-[1rem] pb-[0.5rem] h-[130px]"
        mediaList
      >
        <ListGroup>
          <Block className="flex justify-between items-center">
            <p className="text-[#302F36] text-[18px] leading-8 font-semibold">
              {translate("Your story")}
            </p>
            {/* <Setting2 size="23" color="#5D6980" variant="Bold" /> */}
          </Block>
          <Story
            onAvatarClick={() => null}
            avatar={profile?.profile_image}
            CustomTitle={
              <p className="text-[16px] font-semibold ml-[12px]">
                {profile?.nickname || profile?.firstname}
              </p>
            }
          />{" "}
        </ListGroup>
      </List>
      <hr className="h-[1px]" />
      {storyData?.type === "text" ? <TextStoryFeatures /> : <VideoStoryTabs />}
      <Block className="flex p-4 gap-4 os-1">
        <Button
          className="flex-1 bg-[#0E99FE] button flex justify-center items-center  button-loading h-[45px] text-white text-[16px] font-semibold"
          onClick={postStoryHandler}
          preloader={isPosting}
          small
          disabled={isPosting}
        >
          {translate("Share to story")}
        </Button>
        {/* <Button className="flex-1 bg-[#5D6980]  h-[45px] text-white text-[16px] font-semibold">
          {translate("Close friends")}
        </Button> */}
      </Block>
    </Block>
  );
}

export default EditStoryTab;
