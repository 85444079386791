import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

 // LocationMarker to get the  location and marker moveed code

const LocationMarker = ({ pos, onMove, setMapPosition }) => {
	const [position, setPosition] = useState(null);
	const [bbox, setBbox] = useState([]);
	const map = useMap();
	// current location used this cose
	useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setMapPosition(`${e.latitude}...${e.longitude}`);
			setPosition(e.latlng);
			map.flyTo(e.latlng, map.getZoom());
			//    const radius = e.accuracy;
			//    const circle = L.circle(e.latlng, radius);
			//    circle.addTo(map);
      setBbox(e.bounds.toBBoxString().split(","));
		});
	}, [map]);

	// marker movering code
	return position === null ? null : (
		// marker move code here
		<Marker
			position={position}
			draggable
			autoPan
			eventHandlers={{
				moveend: (event) => {
					onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
				},
			}}
		/>
	);
};

const MapWithLocation = ({ setMapPosition }) => {
	 const [markerPos, setMarkerPos] = useState([setMapPosition]);
	return (
    <MapContainer
      center={[51.4874004, -0.016055]}
      zoom={13}
      zoomControl={false}
      className="h-[378px]"
      scrollWheelZoom
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LocationMarker
        onMove={setMarkerPos}
        pos={markerPos}
        setMapPosition={setMapPosition}
      />
    </MapContainer>
  );
};
export default MapWithLocation;
