import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocation } from "../../redux/features/locationSlice/location";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from '../../assets/images/icons/Forgot.png';
import PhoneInput from "../../components/inputs/phone-input";
import { Profile } from "iconsax-react";

import "./style.css";


import {
  Page,
  Block,
  f7,
  List,
  ListInput,
  ListItem,
  Button,
  PageContent,
} from "framework7-react";


const ViaSms = ({ id }) => {
  const dispatch = useDispatch();
  const deviceInfo = f7.device;
  const [phoneNumber, setPhoneNumber] = useState({
		phone: "",
		country: "",
		countryCode: "",
		validationError: false,
	});
  const [username, setUsername] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const { locationResponse, isLoading } = useSelector(
    (state) => state.locationSlice
  );

  const getLocationHandler = () => {
    const body = {
      login: "",
      password: "",
      device_type: "",
      version_code: '3.0.0',
      os_name: "",
    };

    const config = {
      method: "post",
      url: "/api/user/signin",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(fetchLocation(config));
  };


  const [formData, setFormData] = useState({
    display_name: "",
    country: "",
    country_code: "",
    password: "",
    phone: phoneNumber?.phone,
    username: "",
    signup_type: "phone",
    fcm_token: "",
    device_type: "",
    version_code: "",
    os_name: deviceInfo.os,
    os_version: deviceInfo.osVersion,
    device_manufaturer: "",
    device_model: "",
  });

  return (
		<Page name="ViaSms" pageContent={false}>
			<AuthNavbar />
			<PageContent className="flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px] main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[400px] ml-[30px]">
							<p className=" text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
								{t("Forgot Password ?")}
							</p>
							<p className="text-[18px]text-[--f7-heading-color-color] font-normal text-gray-900">
								{" "}
								{t("Please enter Phone Number")}{" "}
							</p>
						</div>

						<List className="list-none">
							<ListInput
								type="text"
								placeholder={t("Enter email address")}
								className="input-field-normal w-full"
								value={username}
								onInput={(e) => {
									setUsername(e.target.value);
								}}
								required>
								<div slot="media">
									<Profile color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
							<ListItem className="mr-[-25px] mt-4 pl-1">
								<div className="w-full flex mr-[-15px] items-center gap-2">
									<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-0" />
									<p className="text-[14px] text-gray-700">{t("OR")}</p>
									<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-0" />
								</div>
							</ListItem>

							<PhoneInput
								onChange={setPhoneNumber}
							/>

							<Button
								type="submit"
								fill
								className="w[90%] button-set"
								disabled={phoneNumber.validationError}>
								{isLoading ? <Preloader color="white" /> : t("Continue")}
							</Button>
						</List>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<img src={Forgot} />
					</div>
				</Block>
			</PageContent>

			{/* <PageContent className="md:overflow-hidden main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
          <div className="pl-[30px] flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
              <p className=" text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">Forget Password ?</p>
              <p className="text-[18px]text-[--f7-heading-color-color] font-normal text-gray-900">Don't worry! it happend,please pick one of thr option to recover your password.</p>
            </div>
						
						
           
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<img  src={Forgot} />
					</div>
				</Block>
			</PageContent> */}

			{/* Top Navbar */}
			{/* <Navbar large backLink="Back"></Navbar> */}

			{/* <Block>
        <p>This is a dynamic route id: {id}</p>
        <Button fill onClick={() => getLocationHandler()} disabled={isLoading}>
          {`${isLoading ? "Loading..." : "Fetch Location"}`}
        {console.log("isLoading",isLoading)}  
        </Button>
        <p className="break-word">{JSON.stringify(locationResponse)}</p>
      </Block> */}
		</Page>
	);
};
export default ViaSms;