import React, {
	useEffect,
	useState,
	useRef,
	useContext,
	useCallback,
} from "react";
import { Button, f7, useStore } from "framework7-react";
import {
	PauseCircle,
	Send,
	Trash,
	Microphone2,
	DocumentText,
	Location,
	Profile,
	Sms,
} from "iconsax-react";
import { WaveForm, WaveSurfer } from "wavesurfer-react";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import "./style.css";
import { SocketContext } from "../../../socket";
import playButton from "../../../assets/images/icons/playButton.svg";
import stopButton from "../../../assets/images/icons/stopButton.svg";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { truncateText, removeMD } from "../../../utils/functions";
import { uploadFileService } from "../../../redux/features/chatSlice/uploadFile";
import { db } from "../../../js/db";
import MarkdownPreview from "@uiw/react-markdown-preview";
import rehypeSanitize from "rehype-sanitize";
import LightboxThumbnail from "../../misc/lightboxThumbnail";
import MaterialIcon from "../../misc/materialIcon";

const Audiobar = ({ id, toggleAudioInput }) => {
	id = id ? (id !== "posh_ai" ? parseInt(id) : id) : null;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const record = useRef(null);
	const [showStartButton, setShowStartButton] = useState(true);
	const [audioData, setAudioData] = useState(new Blob());
	const [recordedFile, setRecordedFile] = useState(null);
	const [sendFile, setSendFile] = useState(false);
	const socket = useContext(SocketContext);
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(null);
	const [durationSecongs, setDurationSeconds] = useState(null);
	const [remainingTime, setRemainingTime] = useState(null);
	const { loginResponse } = useSelector((state) => state.loginSlice);

	const removeWS = () => {
		if (wavesurferRef.current) {
			wavesurferRef.current.stop();
			wavesurferRef.current.destroy();
			record.current = null;
		}
		toggleAudioInput();
	};

	useUpdateEffect(() => {
		removeWS();
	}, [id]);
	
	const dispatch = useDispatch();
	const { useProfileResponse: user } = useSelector(
		(state) => state.profileSlice
	);
	const wavesurferRef = useRef(null);
	const reply = useStore("reply");

	const handleWSMount = useCallback((waveSurfer) => {
		wavesurferRef.current = waveSurfer;

		if (wavesurferRef.current) {
			if (window) {
				window.surferidze = wavesurferRef.current;
			}

			record.current = wavesurferRef.current.plugins[0];
			record.current.startRecording().then(() => {
				setShowStartButton(false);
			});

			record.current.on("record-pause", (blob) => {
				const d = new Date();
				const file = new File([blob], d.getTime() + ".webm");
				setRecordedFile(file);
				const recordedUrl = URL.createObjectURL(blob);
				setAudioData(recordedUrl);
			});
		}
	}, []);
	useEffect(() => {
		record.current &&
			record.current.on("record-progress", (time) => {
				updateProgress(time);
			});
		if (wavesurferRef.current) {
			wavesurferRef.current.on("audioprocess", handleTimeUpdate);
			wavesurferRef.current.on("seek", handleTimeUpdate);
		}
		return () => {
			if (wavesurferRef.current) {
				wavesurferRef.current.un("audioprocess", handleTimeUpdate);
				wavesurferRef.current.un("seek", handleTimeUpdate);
			}
		};
	}, [wavesurferRef, record]);

	const updateProgress = (time) => {
		const formattedTime = [
			Math.floor((time % 3600000) / 60000),
			Math.floor((time % 60000) / 1000),
		]
			.map((v) => (v < 10 ? "0" + v : v))
			.join(":");

		setDuration(formattedTime);
		setDurationSeconds(parseInt(time / 1000));
	};

	const formatTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = Math.floor(timeInSeconds % 60);
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const handleTimeUpdate = () => {
		if (wavesurferRef.current) {
			const currentTime = wavesurferRef.current.getCurrentTime();
			const totalDuration = wavesurferRef.current.getDuration();
			setDuration(formatTime(totalDuration));
			setRemainingTime(formatTime(totalDuration - currentTime));
		}
	};
	useEffect(() => {
		if (!reply) return;
		db.members.where({ chat_id: id }).toArray((members) => {
			if (reply.sender_id === loginResponse.data.id)
				reply.member = { firstname: "You", lastname: "" };
			else
				reply.member = members.find(
					(member) => member.id === replyCtx.sender_id
				);
			messageinput.current && messageinput.current.focus();
		});
	}, [reply]);

	const saveMessage = async (
		msg,
		identifier,
		type = "text",
		caption = null,
		thumbnail = null,
		file = recordedFile,
		group_id = null
	) => {
		const unix_time = Date.now() / 1000;
		const file_type = "audio";
		const fileUrl = window.URL.createObjectURL(recordedFile);
		const messageObj = {
			allow_forwarding: user?.data?.allow_forwarding || 1,
			allow_sharing: user?.data?.allow_sharing || 1,
			audio_text: "",
			audio_url: fileUrl,
			call_duration: 0,
			caption: caption || "",
			chat_id: id,
			duration: null,
			delivered: false,
			edited: 0,
			file: null,
			file_id: "",
			file_size: file ? file.size : null,
			file_type: file ? file.name?.split(".")?.pop() : null,
			forwarded: 0,
			forwarded_at: null,
			forwarded_unix: "",
			group_files: [],
			group_id: group_id || "",
			id: unix_time,
			identifier: identifier,
			is_group: false,
			is_private: 0,
			is_read: -1,
			language: "en",
			message: msg,
			nickname: user?.nickname || "",
			original_audio_text: "",
			original_audio_url: "",
			original_caption: caption || "",
			original_message: caption ? "" : msg,
			owner_id: 0,
			owner_name: "",
			parent_id: null,
			preview: fileUrl,
			reactions: { like: 0, cry: 0, excited: 0, heart: 0, sad: 0, dislike: 0 },
			sender_id: loginResponse.data.id,
			sender_name: user?.nickname || user?.firstname || user?.name || "",
			sequence: 1,
			story: null,
			thumbnail: thumbnail || fileUrl,
			type: type,
			unix_time: unix_time,
			reply: reply ? JSON.stringify(reply) : "",
			reply_id: reply ? reply.id : "",
		};

		db.messages
			.add(messageObj)
			.then((id) => {
				setTimeout(() => {
					f7.emit("scrollToBottom", id);
				}, 500);
			})
			.catch((ex) => {
				console.error(ex);
				f7.toast.create({ text: "Failed to update DB" }).open();
			});

		db.chats
			.where({ chat_id: messageObj.chat_id })
			.modify({
				un_read_count: 0,
				message: messageObj.message,
				is_read: 0,
				msg_type: messageObj.type,
				firstname: messageObj.sender_name,
				message_sender_id: loginResponse.data.id,
				unix_time: messageObj.unix_time * 1000,
			})
			.then((res) => {});

		// f7.emit("indexChanged");
	};

	const sendMessage = () => {
		const processMessage = (
			attchment,
			identifier,
			is_group,
			group_id,
			caption,
			thumbnail = null
		) => {
			saveMessage(
				"Shared an audio",
				identifier,
				"audio",
				caption,
				thumbnail,
				attchment,
				group_id
			);
			let formData = new FormData();
			formData.append("file", attchment);
			formData.append("type", "audio");
			formData.append("is_group", is_group);
			formData.append("group_id", group_id);
			formData.append("identifier", identifier);
			formData.append("chat_id", id);
			formData.append("caption", caption || "");
			formData.append("duration", durationSecongs);
			formData.append("mime_check", false);
			dispatch(uploadFileService(formData));
		};
		const is_group = false;
		const groupid = uuidv4();
		const identifier = uuidv4();
		const group_id = is_group === false ? identifier : groupid;
		processMessage(recordedFile, identifier, is_group, group_id);
		setSendFile(false);
		removeWS();
	};

	useUpdateEffect(() => {
		if (recordedFile && sendFile) {
			sendMessage();
		}
	}, [recordedFile, sendFile]);

	return (
		<div className="toolbar messagebar md:pl-[12px] pl-[6px] md:pr-[24px] pr-[6px] py-[15px] md:gap-[20px]">
			<div className="toolbar-inner">
				<div className="messagebar-area my-0 rounded-[10px] md:rounded-none">
					{reply && (
						<div className="flex align-center justify-between">
							<div
								className={`${
									reply?.type !== "text" ? "h-[95px]" : "h-[55px]"
								} relative w-full rounded-l-[10px] ${
									reply?.type === "text" ? "rounded-r-[10px]" : null
								} highlight px-[18px] flex flex-col items-start justify-center bg-[#F4F4F4] mb-[8px] truncate ...`}>
								{reply && (
									<span className="text-primary font-semibold truncate ...">{`${
										reply.nickname || reply.sender_name
									}`}</span>
								)}
								<span className="text-body text-sm w-full text-ellipsis overflow-hidden whitespace-nowrap truncate ...">
									{reply ? (
										reply.type === "text" ? (
											<MarkdownPreview
												source={removeMD(
													reply.message.replace(
														/@\[([^\]]+)\]\((\d+)\)/g,
														"@$1"
													)
												)}
												rehypePlugins={[rehypeSanitize]}
											/>
										) : (
											<>
												{" "}
												{reply.type === "contact" && (
													<Profile
														size="15"
														color="#5D6980"
														variant="Bold"
														className="inline-block align-text-top mr-[2px] mt-[2px]"
													/>
												)}
												{reply.type === "audio" && (
													<Microphone2
														size="15"
														color="#5D6980"
														variant="Bold"
														className="inline-block align-text-top mr-[2px] mt-[2px]"
													/>
												)}
												{reply.type === "location" && (
													<Location
														size="15"
														color="#5D6980"
														variant="Bold"
														className="inline-block align-text-top mr-[2px] mt-[2px]"
													/>
												)}
												{reply.type === "mms" && (
													<Sms
														size="15"
														color="#5D6980"
														variant="Bold"
														className="inline-block align-text-top mr-[2px] mt-[2px]"
													/>
												)}
												{reply.type !== "contact" &&
													reply.type !== "audio" &&
													reply.type !== "location" &&
													reply.type !== "mms" && (
														<DocumentText
															size="15"
															color="#5D6980"
															variant="Bold"
															className="inline-block align-text-top mr-[2px] mt-[2px]"
														/>
													)}
												{""}
												<MarkdownPreview
													source={
														reply.caption
															? removeMD(
																	truncateText(
																		reply.caption?.replace(
																			/@\[([^\]]+)\]\((\d+)\)/g,
																			"@$1"
																		)
																	)
															  )
															: reply.type === "location"
															? `Location`
															: reply.type === "contact"
															? removeMD(
																	truncateText(
																		JSON.parse(reply.message).contactName
																	)
															  ) ||
															  removeMD(
																	truncateText(
																		JSON.parse(reply.message)
																			.contactTypeInfoList[0].phoneNumber
																	)
															  )
															: reply.type === "mms"
															? JSON.parse(reply.message).message.trim()
															: removeMD(
																	truncateText(
																		reply.message?.replace(
																			/@([^()\s]+)(\([^()]+\))?/g,
																			"@$1"
																		)
																	)
															  )
													}
													rehypePlugins={[rehypeSanitize]}
												/>
											</>
										)
									) : (
										""
									)}
								</span>
							</div>
							{reply && reply?.type !== "text" && reply?.type !== "mms" && (
								<div
									className={`w-[88px] h-[55px] rounded-r-[10px] bg-[#f4f4f4] ${
										reply?.type === "contact"
											? "flex flex-col align-center justify-center"
											: ""
									}`}>
									{reply?.type !== "contact" &&
									reply?.type !== "audio" &&
									reply?.type !== "location" ? (
										<LightboxThumbnail
											item={reply}
											className={`${
												reply?.type !== "image" &&
												reply?.type !== "video" &&
												reply?.type !== "mms"
													? "scale-[2.5] mt-[32px]"
													: "w-[88px] h-[95px] rounded-r-[10px]"
											}`}
										/>
									) : reply?.type === "contact" ? (
										<img
											src={userimg}
											alt="contact"
											className="w-[72px] h-auto rounded-full"
										/>
									) : reply?.type === "audio" ? (
										<i className="kt-ft record scale-[2.5] mt-[32px] mr-[8px]" />
									) : reply?.type === "location" ? (
										<MapContainer
											zoomControl={false}
											dragging={false}
											keyboard={false}
											scrollWheelZoom={false}
											center={reply.message
												.split("...")
												.map((coord) => parseFloat(coord.trim()))}
											zoom={20}
											placeholder={
												<SkeletonBlock effect="wave"></SkeletonBlock>
											}>
											<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
											<Marker
												position={reply.message
													.split("...")
													.map((coord) => parseFloat(coord.trim()))}></Marker>
										</MapContainer>
									) : null}
								</div>
							)}
							<div className="pl-1 md:pl-0 md:ml-[20px]">
								<div
									className={`${
										reply?.type !== "text" ? "h-[95px]" : "h-[55px]"
									} flex flex-col items-center justify-center mb-[8px] md:-mr-[5px]`}>
									<Button
										className="h-[40px]"
										onClick={() => {
											reply && f7.store.dispatch("setReply", null);
											if (metaAvailable) {
												setMetaAvailable(null);
												setMetaHidden(true);
											}

											document.body.classList.remove("expanded-messagebar");
											messageinput.current && messageinput.current.focus();
										}}>
										<MaterialIcon
											icon="close"
											size={24}
											weight={400}
											color="#5D6980"
										/>
									</Button>
								</div>
							</div>
						</div>
					)}
					<div className="flex justify-end items-center h-[48px] gap-[20px]">
						<Button className="h-[24px] p-0" onClick={removeWS}>
							<Trash size="24" color="#5D6980" variant="Bold" />
						</Button>
						<div className="h-full w-3/4 md:w-[353px] gap-2.5 px-[12px] bg-[#F8F8F8] rounded-full flex items-center justify-flex-start">
							{showStartButton ? (
								<Button
									className="p-0 h-[40px]"
									onClick={() => {
										wavesurferRef.current.playPause();
										setIsPlaying(wavesurferRef.current.isPlaying());
									}}>
									{isPlaying ? (
										<img
											src={stopButton}
											className="w-[20px] h-[20px]"
											alt="Stop Button"
										/>
									) : (
										<img
											src={playButton}
											className="w-[20px] h-[20px]"
											alt="Play Button"
										/>
									)}
								</Button>
							) : (
								<span className="text-base leading-[21px] text-secondary">
									{duration}
								</span>
							)}
							<WaveSurfer
								plugins={[
									{
										plugin: RecordPlugin,
										key: "record",
										options: {
											scrollingWaveform: true,
											renderRecordedAudio: true,
											mimeType: "audio/webm",
										},
									},
								]}
								onMount={handleWSMount}
								cursorColor="transparent"
								container="#waveform"
								waveColor="#37aafe"
								progressColor="#5D6980">
								<WaveForm></WaveForm>
							</WaveSurfer>
							{showStartButton && (
								<span className="text-base leading-[21px] text-secondary">
									{remainingTime}
								</span>
							)}
						</div>
						<Button
							className="h-[24px] p-0"
							onClick={() => {
								record.current &&
									showStartButton &&
									record.current.resumeRecording();
								record.current &&
									!showStartButton &&
									record.current.pauseRecording();
								setShowStartButton(!showStartButton);
							}}>
							{showStartButton ? (
								<Microphone2 size="24" color="#E05047" variant="Outline" />
							) : (
								<PauseCircle size="24" color="#E05047" variant="Outline" />
							)}
						</Button>

						<Button
							className="h-[24px] p-0"
							onClick={() => {
								if (record.current && !showStartButton) {
									record.current.pauseRecording();
									setShowStartButton(true);
									setSendFile(true);
								} else {
									sendMessage();
								}
							}}>
							<Send size="24" color="#5D6980" variant="Bold" />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Audiobar;
