import { f7 } from "framework7-react";
import { LISTENERS } from "../constants/socket";
import { db } from "../js/db";
import { deleteCookie } from "../utils/functions";
import { AUTHTOKEN_COOKIE } from "../constants";
import { persistor } from "../redux/store";

export const logout = () => {
	if (typeof socket !== "undefined") {
		socket.disconnect();
	}
	deleteCookie(AUTHTOKEN_COOKIE);
	sessionStorage.removeItem("encryptionKey");
	localStorage.removeItem("keys");
	persistor.purge();
	db.delete()
		.then(() => {
		})
		.catch((err) => {
			console.error("Could not delete database");
		})
		.finally(() => {
			window.location.reload(true);
		});
};

const userOnlineStatus = (data) => {
	f7.store.dispatch("setUserStatus", data);
};

const createChat = (chatDetail) => {
	return new Promise((resolve, reject) => {
		db.chats
			.put({
				is_muted: chatDetail.is_muted ? chatDetail.is_muted : 0,
				chat_id: chatDetail.chat_id,
				type: chatDetail.chat_type ? chatDetail.chat_type : "single",
				duration: chatDetail.duration ? chatDetail.duration : 0,
				nickname:
					chatDetail.chat_type == "group"
						? chatDetail.group_name
						: chatDetail.sender_name,
				is_read: chatDetail.is_read ? chatDetail.is_read : 0,
				msg_type: chatDetail.type ? chatDetail.type : "text",
				owner_id: chatDetail.sender_id,
				message_sender_id: chatDetail.sender_id,
				firstname: chatDetail.firstname || chatDetail.sender_name,
				hide_profile_picture: 0,
				profile_image: chatDetail.profile_image,
				last_message_id: chatDetail.id,
				allow_forwarding: chatDetail.allow_forwarding
					? chatDetail.allow_forwarding
					: 1,
				allow_sharing: chatDetail.allow_sharing ? chatDetail.allow_sharing : 1,
				is_private_chat: chatDetail.is_private ? chatDetail.is_private : 0,
				message: chatDetail.message,
				un_read_count: 0,
				unix_time: Date.now(),
				updated_at: chatDetail.updated_at,
				reaction: null,
				user_id: chatDetail.chat_type == "group" ? 0 : chatDetail.sender_id,
				disappearing_duration: chatDetail.disappearing_duration
					? chatDetail.disappearing_duration
					: null,
				accepted_by: chatDetail.accepted_by ? chatDetail.accepted_by : null,
				calling_feature: chatDetail.calling_feature,
				mobile: chatDetail.phone || chatDetail.mobile || "",
				sender_id: chatDetail.sender_id,
				who_can_send_mes: "all",
			})
			.then((id) => resolve(id))
			.catch((ex) => {
				console.error(ex);
				reject(ex);
			});
	});
};

export const onClearMessagesEventHandler = async (data) => {
	const uid = localStorage.getItem("uid");
	if (data.user_id == uid && data.chat_id) {
		db.messages
			.where({
				chat_id: parseInt(data.chat_id),
			})
			.delete()
			.then((deleted) => {
			})
			.catch((ex) => {
			});

		db.chats
			.where({ chat_id: parseInt(data.chat_id) })
			.modify({
				message: "",
			})
			.then((id) => {
			})
			.catch((ex) => {
			});
	}
};

export const onUnBlockContactEventHandler = async (data) => {
	const uid = localStorage.getItem("uid");
	if (data.user_id == uid && data.chat_id && data.block_id) {
		db.chats
			.where({ chat_id: parseInt(data.chat_id) })
			.modify({ is_blocked: 0 })
			.then((id) => {
			})
			.catch((ex) => {
			});
		db.users
			.where({ id: data.block_id })
			.modify({ blocked: 0 })
			.then((id) => {
			})
			.catch((ex) => {
			});
	}
};
export const onBlockContactEventHandler = async (data) => {
	const uid = localStorage.getItem("uid");
	if (data.user_id == uid && data.chat_id && data.block_id) {
		db.chats
			.where({ chat_id: parseInt(data.chat_id) })
			.modify({ is_blocked: 1 })
			.then((id) => {
			})
			.catch((ex) => {
			});
		db.users
			.where({ id: data.block_id })
			.modify({ blocked: 1 })
			.then((id) => {
			})
			.catch((ex) => {
			});
	}
};
export const onDeleteChatsEventHandler = async (data) => {
	const uid = localStorage.getItem("uid");
	if (
		data.user_id == uid &&
		data.chat_ids &&
		Array.isArray(data.chat_ids) &&
		data.chat_ids.length > 0
	) {
		for (const chatId of data.chat_ids) {
			db.messages
				.where({
					chat_id: parseInt(chatId),
				})
				.delete()
				.then((deleted) => {
				})
				.catch((ex) => {
				});
			db.chats
				.where({ chat_id: parseInt(chatId) })
				.delete()
				.then((id) => {
				})
				.catch((ex) => {
				});
		}
	}
};
export const newMessageEventHandler = async (data) => {
	if (data.chat_id > 0) {
		let unix_time = data.unix_time ? parseInt(data.unix_time) : Date.now();
		data.chat_id = parseInt(data.chat_id);
		let chatObj = await db.chats.get(data.chat_id);
		if (!chatObj) {
			const newChatId = await createChat(data);
			chatObj = await db.chats.get(newChatId);
		}
		f7.emit(LISTENERS.NEW_MESSAGE, data);
		if (
			!chatObj?.is_muted &&
			parseInt(localStorage.getItem("uid")) !== parseInt(data.sender_id) &&
			JSON.parse(
				localStorage.getItem(
					`${
						chatObj?.type === "single"
							? "text-notification"
							: "group-text-notification"
					}`
				) || "true"
			)
		) {
			f7.emit("notification", data);
		}

		const chatData = {
			//un_read_count: chatObj.un_read_count+1,
			is_read: 0,
			message: data.message,
			...(chatObj?.type &&
				chatObj?.type === "group" && {
					firstname: data.sender_name,
				}),
			message_sender_id: parseInt(data.sender_id),
			unix_time: unix_time,
			msg_type: data.type,
		};

		db.chats
			.where({ chat_id: data.chat_id })
			.modify(chatData)
			.then((id) => {
			})
			.catch((ex) => {
			});
		db.messages
			.put(data)
			.then((id) => {
				console.log("newMessageEventHandler", parseInt(data.sender_id), parseInt(localStorage.getItem("uid")), parseInt(data.sender_id) === parseInt(localStorage.getItem("uid")));
				if (
					parseInt(data.sender_id) === parseInt(localStorage.getItem("uid"))
				) {
					f7.emit("clearUnseen");
					socket?.emit(
						ENDPOINTS.READ_ALL_MESSAGES,
						JSON.stringify({
							chat_id: id,
						})
					);
				} else {
					f7.store.dispatch("addUnseenMessages", {
						messageId: id,
						chatId: parseInt(data.chat_id),
					});
				}
			})
			.catch((ex) => {
			});

		if (data.type === "member") {
			const jsonData = JSON.parse(data.message);
			if (jsonData.type === "add") {
				const member = {
					allow_forwarding: 1,
					allow_sharing: 1,
					chat_id: data.chat_id,
					id: jsonData.id,
					profile_image: jsonData.profile_image,
					hide_profile_picture: 0,
					firstname: jsonData.firstname,
					lastname: jsonData.lastname || "",
					is_admin: 0,
					nickname:
						jsonData.nickname || jsonData.firstname || jsonData.username,
					username: jsonData.username || "",
				};
				db.members
					.where({
						chat_id: parseInt(data.chat_id),
						id: parseInt(jsonData.id),
					})
					.first()
					.then((memberObj) => {
						if (!memberObj) {
							db.members.add(member);
						}
					});
			} else {
				db.members
					.where({
						chat_id: parseInt(data.chat_id),
						id: parseInt(jsonData.id),
					})
					.delete()
					.then((deleted) => {
					});
			}
		}
	}
};
export const messageDeletedEventHandler = (data) => {
	if (data.messages) {
		data.messages.map(async (item, index) => {
			await db.messages.delete(parseInt(item));
			f7.store.dispatch("removeUnseenMessages", {
				messageId: item.id,
				chatId: item.chat_id,
			});
			db.messages.orderBy("unix_time").last((lastMsg) => {
				db.chats.get(lastMsg.chat_id).then((chat) => {
					const chatData = {
						message: lastMsg.message,
						...(chat.type === "group" && { firstname: lastMsg.sender_name }),
						message_sender_id: parseInt(lastMsg.sender_id),
						unix_time: lastMsg.unix_time,
						msg_type: lastMsg.type,
					};
					db.chats
						.where({ chat_id: lastMsg.chat_id })
						.modify(chatData)
						.then((id) => {
						})
						.catch((ex) => {
						});
				});
			});
		});
	}
};
export const editMessageEventHandler = (data) => {
	if (data.message_id) {
		db.messages.where({ id: parseInt(data.message_id) }).modify({
			message: data.message,
			unix_time: Date.now(),
		});
	}
};
export const editCaptionEventHandler = (data) => {
	if (data.message_id) {
		db.messages.where({ id: parseInt(data.message_id) }).modify({
			caption: data.caption,
			original_caption: data.original_caption,
			unix_time: Date.now(),
		});
	}
};
export const messageUpdatedEventHandler = (data) => {
	if (data.message_id && data.language) {
		db.messages.where({ id: parseInt(data.message_id) }).modify({
			language: data.language,
		});
	}
};

export const messageDeliveredEventHandler = (data) => {
	if (data.message_id && data.all == 1) {
		db.chats.where({ chat_id: parseInt(data.chat_id) }).modify({
			is_read: 1,
		});
		db.messages
			.where({
				chat_id: parseInt(data.chat_id),
				sender_id: parseInt(localStorage.getItem("uid")),
				is_read: 0,
			})
			.modify({
				is_read: 1,
			});
		db.messageStatus
			.where({
				message_id: data.message_id,
				user_id: data.user_id,
			})
			.first()
			.then((memberObj) => {
				if (!memberObj) {
					data.status = "delivered";
					db.messageStatus.add(data);
				}
			})
			.catch((ex) => {
			});
	}
	if (data.chat_id) {
		db.chats.where({ chat_id: parseInt(data.chat_id) }).modify({
			is_read: 1,
		});
	}
};
export const messagesSeenEventHandler = (data) => {
	if (data.chat_id && data.all == 1) {
		db.chats
			.where({ chat_id: parseInt(data.chat_id) })
			.first()
			.then((chat) => {
				db.messages
					.where({
						chat_id: parseInt(data.chat_id),
						sender_id: parseInt(localStorage.getItem("uid")),
						is_read: 1,
					})
					.modify({
						is_read: 2,
					})
					.then((id) => {
					})
					.catch((ex) => {
					});
				/*db.chats.where({ chat_id: parseInt(data.chat_id) }).modify({
					is_read: 2,
				});*/
			});
	}
	if (data.message_id) {
		db.messageStatus
			.where({
				message_id: data.message_id,
				user_id: data.user_id,
			})
			.first()
			.then((memberObj) => {
				data.status = "seen";
				if (!memberObj) {
					db.messageStatus.add(data);
				} else {
					db.messageStatus
						.where({
							message_id: data.message_id,
							user_id: data.user_id,
						})
						.modify(data)
						.then((id) => {
						})
						.catch((ex) => {
						});
				}
			})
			.catch((ex) => {
			});
	}
};
export const chatSeenEventHandler = (data) => {
	if (data.chat_id) {
		f7.emit(LISTENERS.ALL_MESSAGES_READ, data);
		// update messages where is_read<2 and sender_id is mine and chat_id
	}
};

export const reactionEventHandler = async (data) => {
	if (data.type === "add") {
		const chatObj = await db.chats.get(data.chat_id);
		if (
			!chatObj?.is_muted &&
			parseInt(localStorage.getItem("uid")) !== parseInt(data.user_id) &&
			JSON.parse(localStorage.getItem("reaction-notification") || "true")
		) {
			f7.emit("reactionNotification", data);
		}
	}
	if (data.message_id) {
		db.messages.where({ id: parseInt(data.message_id) }).modify({
			reactions: data.reaction,
		});
	}
};

let typingTimer = {};

export const typingEventHandler = (data) => {
	typingTimer[data.id] && clearTimeout(typingTimer[data.id]);
	db.chats
		.where({ chat_id: parseInt(data.chat_id) })
		.first()
		.then((chat) => {
			f7.store.dispatch("setTyping", { ...data, type: chat.type });
			typingTimer[data.id] = setTimeout(() => {
				f7.store.dispatch("removeTyping", { ...data, type: chat.type });
			}, 3000);
		});
};

export const stopTypingEventHandler = (data) => {
	db.chats
		.where({ chat_id: parseInt(data.chat_id) })
		.first()
		.then((chat) => {
			f7.store.dispatch("removeTyping", { ...data, type: chat.type });
		});
};

export const onNewCallEventHandler = (data) => {
	f7.emit(LISTENERS.START_NEW_CALL, data);
};
export const onNewCallReceivedEventHandler = (data) => {
	f7.emit(LISTENERS.NEW_CALL_RECEIVED, data);
};

export const onConversationsStatusEventHandler = (data) => {
	//console.log("onConversationsStatusEventHandler", data);
	for (const item of data) {
		db.chats
			.where({ chat_id: item.chat_id })
			.modify({ un_read_count: item.un_read_count })
			.then((updated) => {
				//console.log("onConversationsStatusEventHandler updated " + updated + " items");
			});
	}
};
export const onCallAcceptedEventHandler = (data) => {
	f7.emit(LISTENERS.CALL_ANSWERED, data);
	f7.emit(LISTENERS.CALL_ACCEPTED, data);
};
export const onCallEndedEventHandler = (data) => {
	f7.emit(LISTENERS.CALL_ENDED, data);
};

export const bindAppEvents = (socket) => {
	socket.on(LISTENERS.UNAUTHORIZED, logout);
	socket.on(LISTENERS.GLOBAL, (data) => {
		if (data.includes("unauthrized")) {
			logout();
		}
	});
	socket.on(LISTENERS.USER_STATUS, userOnlineStatus);
	socket.on(LISTENERS.NEW_MESSAGE, newMessageEventHandler);
	socket.on(LISTENERS.MESSAGE_DELETED, messageDeletedEventHandler);
	socket.on(LISTENERS.EDIT_MESSAGE, editMessageEventHandler);
	socket.on(LISTENERS.EDIT_CAPTION, editCaptionEventHandler);
	socket.on(LISTENERS.MESSAGE_LANG_UPDATED, messageUpdatedEventHandler);
	socket.on(LISTENERS.MESSAGES_DELIVERED, messageDeliveredEventHandler);
	socket.on(LISTENERS.ALL_MESSAGES_READ, chatSeenEventHandler);
	socket.on(LISTENERS.SEEN_MESSAGE, messagesSeenEventHandler);
	socket.on(LISTENERS.REACTION, reactionEventHandler);
	socket.on(LISTENERS.MESSAGE_TYPING_GROUP, typingEventHandler);
	socket.on(LISTENERS.MESSAGE_STOP_TYPING_GROUP, stopTypingEventHandler);
	socket.on(LISTENERS.START_NEW_CALL, onNewCallEventHandler);
	socket.on(LISTENERS.NEW_CALL_RECEIVED, onNewCallReceivedEventHandler);
	socket.on(LISTENERS.CALL_ANSWERED, onCallAcceptedEventHandler);
	socket.on(LISTENERS.CALL_ENDED, onCallEndedEventHandler);
	socket.on(LISTENERS.CALL_ACCEPTED, onCallAcceptedEventHandler);
	socket.on(LISTENERS.CONVERSATIONS_STATUS, onConversationsStatusEventHandler);
	socket.on(LISTENERS.DELETE_CHATS, onDeleteChatsEventHandler);
	socket.on(LISTENERS.CLEAR_MESSAGES, onClearMessagesEventHandler);
	socket.on(LISTENERS.BLOCK_CONTACT, onBlockContactEventHandler);
	socket.on(LISTENERS.UNBLOCK_CONTACT, onUnBlockContactEventHandler);

	//write more events
};

export const unbindAppEvents = (socket) => {
	socket.off(LISTENERS.UNAUTHORIZED, logout);
	socket.off(LISTENERS.GLOBAL, (data) => {
		if (data.includes("unauthrized")) {
			logout();
		}
	});
	socket.off(LISTENERS.USER_STATUS, userOnlineStatus);
	socket.off(LISTENERS.NEW_MESSAGE, newMessageEventHandler);
	socket.off(LISTENERS.MESSAGE_DELETED, messageDeletedEventHandler);
	socket.off(LISTENERS.EDIT_MESSAGE, editMessageEventHandler);
	socket.off(LISTENERS.EDIT_CAPTION, editCaptionEventHandler);
	socket.off(LISTENERS.MESSAGE_LANG_UPDATED, messageUpdatedEventHandler);
	socket.off(LISTENERS.MESSAGES_DELIVERED, messageDeliveredEventHandler);
	socket.off(LISTENERS.ALL_MESSAGES_READ, chatSeenEventHandler);
	socket.off(LISTENERS.SEEN_MESSAGE, messagesSeenEventHandler);
	socket.off(LISTENERS.REACTION, reactionEventHandler);
	socket.off(LISTENERS.MESSAGE_TYPING_GROUP, typingEventHandler);
	socket.off(LISTENERS.MESSAGE_STOP_TYPING_GROUP, stopTypingEventHandler);
	socket.off(LISTENERS.START_NEW_CALL, onNewCallEventHandler);
	socket.off(LISTENERS.NEW_CALL_RECEIVED, onNewCallReceivedEventHandler);
	socket.off(LISTENERS.CALL_ANSWERED, onCallAcceptedEventHandler);
	socket.off(LISTENERS.CALL_ENDED, onCallEndedEventHandler);
	socket.off(LISTENERS.CALL_ACCEPTED, onCallAcceptedEventHandler);
	socket.off(LISTENERS.CONVERSATIONS_STATUS, onConversationsStatusEventHandler);
	//debind all here
};
