import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	chatMessagesResponse: {},
	isLoading: false,
	error: false,
};

export const chatMessageSlice = createSlice({
	name: "chatMessage",
	initialState,
	reducers: {
		saveChatMessagesResponse: (state, action) => {
			state.chatMessagesResponse = action.payload;
		},
		resetChatMessagesResponse: (state) => {
			state.chatMessagesResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(chatMessageService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(chatMessageService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload?.data?.chats) {
					return;
				}
				const data = action.payload?.data;
				state.chatMessagesResponse = action.payload;

				db.messages
					.bulkPut(data?.chats)
					.then(async function (lastKey) {
					})
					.catch(Dexie.BulkError, function (err) {
						state.isLoading = false;
					});
				db.members
					.bulkPut(data?.members)
					.then(async function (lastKey) {
					})
					.catch(Dexie.BulkError, function (err) {
					});

				db.members
					.where("chat_id")
					.equals(data?.chats[0]?.chat_id)
					.toArray()
					.then((members) => {
						if (members.length > 0) {
							let newMembers = data?.members.map((m) => m.id);
							let oldMembers = members.map((m) => m.id);
							let toDelete = oldMembers.filter((m) => !newMembers.includes(m));
							if (toDelete.length > 0) {
								db.members
									.where("id")
									.anyOf(toDelete)
									.delete()
									.then(function (deleteCount) {
									})
									.catch(function (error) {
										console.error("Error: " + error);
									});
							}
						}
					})
					.catch(function (error) {
						console.error("Error: " + error);
					});
			})
			.addCase(chatMessageService.rejected, (state, action) => {
				state.isLoading = false;
				state.chatMessagesResponse = {};
				state.error = action.error.message;
			});
	},
});

const chatMessageService = createAsyncThunk(
	"chat/getChatMessages",
	async (values) => {
		if (!values) return;
		if (values.chat_id > 0) {
			return post("/chat/get_chat_messages", {
				type: "application/x-www-form-urlencoded",
				data: { encodedParams: await encrypt(JSON.stringify(values)) },
				// req_id: "get_chat_messages",
			});
		} else {
			return;
		}
	}
);

export const { saveChatMessagesResponse, resetChatMessagesResponse } =
	chatMessageSlice.actions;
export { chatMessageService };

export default chatMessageSlice.reducer;
