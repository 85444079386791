import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from '../../assets/images/icons/Forgot.png';
import {  Profile } from "iconsax-react";
import {  STATUS_CODE } from "../../constants";
import {forgotPwdThroughEmailService} from '../../redux/features/passwordResetSlice/requestpwdToken';


import {
  Page,
  Link,
  Block,
  f7,
  Button,
  List,
  PageContent,Preloader,
  ListInput 
} from "framework7-react";
import { useUpdateEffect } from "react-use";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../components/misc/materialIcon";



const Viaemail = ({ f7router }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoadingres, setIsLoadingRes] = useState(false);
  const [responsedata , setResponseData] = useState(null)
  const [username, setUsername] = useState("");
  const [error, setError] = useState('');
  const[isValidEmail , setIsValidEmail]=  useState("");
  const { t, i18n } = useTranslation();
  const { pwdResetToken,restOTP,FRGTEmailPWD, isLoading,} = useSelector(
		(state) => state.pwdResetTokenSlice
	);

 
  const handleEmailchecked = (e)=>{
    const enteredEmail = e.target.value;
    setUsername(enteredEmail);
    setIsValidEmail(enteredEmail === "" || validateEmail(enteredEmail))
    setEmail(e.target.value)
  }
  const validateEmail = (username) =>{
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return emailPattern.test(username)
  }
	const handleSubmit = () => {
		 setIsLoadingRes(true);
    dispatch(forgotPwdThroughEmailService({email:email}))
	}
  useUpdateEffect(() => {
    if (FRGTEmailPWD?.code === STATUS_CODE.SUCCESS) {
	  f7.toast.create({closeTimeout: 5000, text: t(FRGTEmailPWD.message) }).open();  //after token received do processing here
    f7router.navigate('/NextToEmail/');
    } else {
      ///f7 toast here  error
      f7.toast.create({closeTimeout: 5000, text: t(FRGTEmailPWD.message) }).open();
    }
  }, [FRGTEmailPWD, error])
  return (
		<Page name="Viaemail" pageContent={false}>
			<AuthNavbar />

			<PageContent className="md:overflow-hidden flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px] main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[400px] pl-[20px]">
							<p className="flex items-center justify-start text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
								<Link back>
									<MaterialIcon icon="arrow_back_ios" size={32} />
								</Link>
								{t("Forgot Password ?")}
							</p>
							<p className="leading-5 text-bases font-normal text-color-[--f7-chat-heading-color] font-[--f7-font-family]">
								{t(
									"Please enter your email address associated with your accout to receive further instruction."
								)}{" "}
							</p>
						</div>
						<List className="list-none w-[400px]">
							<ListInput
								type="text"
								placeholder={t("Enter email address")}
								className={`input-field-normal w-full ${
									username && !isValidEmail > 0 ? "invalid" : ""
								}`}
								value={email}
								onChange={handleEmailchecked}
								onInput={(e) => {
									setUsername(e.target.value);
								}}
								required>
								<div slot="media">
									<Profile color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
							<p className=" ml-[40px] mt-[10px] error-text">{message}</p>
							{username !== "" && !isValidEmail && (
								<p className=" ml-[40px] mt-[10px] error-text">
									{t("Please enter a valid email")} {t("gane@email.com")}{" "}
								</p>
							)}
							{responsedata && (
								<p className=" ml-[40px] mt-[10px] error-text">
									{JSON.stringify(responsedata, null, 2)}
								</p>
							)}
							<Button
								fill
								onClick={handleSubmit}
								className="w-full mt-[20px]  button-set"
								disabled={!email.length || isLoading}>
								{isLoading ? <Preloader color="white" /> : t("Continue")}
							</Button>
						</List>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<img src={Forgot} />
					</div>
				</Block>
			</PageContent>
		</Page>
	);
};
export default Viaemail;