import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";

const initialState = {
  acceptRejectRequestResponse: {},
  isLoading: false,
  error: false,
};

export const acceptRejectRequestSlice = createSlice({
  name: "acceptRejectRequest",
  initialState,
  reducers: {
    saveAcceptRejectRequestResponse: (state, action) => {
      state.acceptRejectRequestResponse = action.payload;
    },
    resetAcceptRejectRequestResponse: (state) => {
      state.acceptRejectRequestResponse = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptRejectRequestsService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptRejectRequestsService.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!action.payload) return;
        state.acceptRejectRequestResponse = action.payload;
      })
      .addCase(acceptRejectRequestsService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

const acceptRejectRequestsService = createAsyncThunk(
	"user/acceptRejectRequests",
	async (values) => {
		if(!values) return;

		return post("/user/accept_reject_request", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveAcceptRejectRequestResponse, resetAcceptRejectRequestResponse } =
  acceptRejectRequestSlice.actions;
export { acceptRejectRequestsService };

export default acceptRejectRequestSlice.reducer;
