const IV = new Uint8Array(16).fill(0);

export const generateKeyPair = async () => {
	const keypair = await crypto.subtle.generateKey(
		{
			name: "RSA-OAEP",
			modulusLength: 2048,
			publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
			hash: { name: "SHA-256" },
		},
		true,
		["encrypt", "decrypt"]
	);

	return keypair;
};
export const base64ToArrayBuffer = (base64) => {
	const binaryString = atob(base64);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
};
export const privateDecrypt = async (privateKey, encryptedMessageBase64) => {
	const encryptedMessage = base64ToArrayBuffer(encryptedMessageBase64);
	try {
		const decrypted = await crypto.subtle.decrypt(
			{
				name: "RSA-OAEP",
			},
			privateKey,
			encryptedMessage
		);
		return new TextDecoder().decode(decrypted);
	} catch (error) {
		console.error("Error decrypting message:", error);
		throw error;
	}
};
export const stringKeyToUint8Array = (keyString) => {
	const encoder = new TextEncoder();
	return encoder.encode(keyString);
};

export const pemToArrayBuffer = (pem) => {
	const b64Lines = pem.replace(/-----[^-]+-----/g, "").replace(/\s+/g, "");
	const b64 = atob(b64Lines);
	const buffer = new ArrayBuffer(b64.length);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < b64.length; i++) {
		view[i] = b64.charCodeAt(i);
	}
	return buffer;
};
export const getPrivateKeyFromPem = async (pem) => {
	const binaryDer = pemToArrayBuffer(pem);
	return await window.crypto.subtle.importKey(
		"pkcs8",
		binaryDer,
		{
			name: "RSA-OAEP",
			hash: "SHA-256",
		},
		true,
		["decrypt"]
	);
};

export const getEncryptionKey = async () => {
	const sse = sessionStorage.getItem("encryptionKey");
	if (sse) {
		return sse;
	}
	
	const localKeys = JSON.parse(localStorage.getItem("keys"));
	const privateKey = await getPrivateKeyFromPem(localKeys.privateKey);
	const encryptionKey = await privateDecrypt(
		privateKey,
		localKeys.encryptionKey
	);
	return encryptionKey;
};
export const arrayBufferToBase64 = (buffer) => {
	const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
	return btoa(binary);
};
export const decrypt = async (encrypted64Data) => {
	const encryptionKey = await getEncryptionKey();	
	try {
		// Import the key
		const importedKey = await crypto.subtle.importKey(
			"raw",
			stringKeyToUint8Array(encryptionKey),
			{ name: "AES-CBC" },
			false,
			["decrypt"]
		);

		// Convert the base64-encoded encrypted data to an ArrayBuffer
		const encryptedArrayBuffer = base64ToArrayBuffer(encrypted64Data);

		// Decrypt the data using AES-256-CBC
		const decrypted = await crypto.subtle.decrypt(
			{ name: "AES-CBC", iv: IV },
			importedKey,
			encryptedArrayBuffer
		);
		const decoder = new TextDecoder();
		return decoder.decode(decrypted);
	} catch (error) {
		console.error("Error decrypting message:", error,encryptionKey);
		return false;
		//throw error;
	}
};

export const encrypt = async (message) => {
	if (typeof message !== 'string') message = JSON.stringify(message);
	
	const encryptionKey = await getEncryptionKey();
	try {
		// Import the key
		const importedKey = await crypto.subtle.importKey(
			"raw",
			stringKeyToUint8Array(encryptionKey),
			{ name: "AES-CBC" },
			false,
			["encrypt"]
		);
		const encoder = new TextEncoder();
		const data = encoder.encode(message, "utf-8");
		const encrypted = await crypto.subtle.encrypt(
			{ name: "AES-CBC", iv: IV },
			importedKey,
			data
		);
		return arrayBufferToBase64(encrypted);
	} catch (error) {
		console.error("Error encrypting message:", error);
		throw error;
	}
};

export const exportKey = async (key, type = "public") => {
	const keydata = await window.crypto.subtle.exportKey(
		type === "public" ? "spki" : "pkcs8",
		key
	);
	const pem = toPEM(keydata, type);

	if (pem) {
		return pem;
	} else {
		return null;
	}
};

const toPEM = (keydata, type) => {
	var keydataS = arrayBufferToString(keydata);
	var keydataB64 = window.btoa(keydataS);
	var keydataB64Pem = formatAsPem(keydataB64, type);
	return keydataB64Pem;
};

const arrayBufferToString = (buffer) => {
	var binary = "";
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return binary;
};

const formatAsPem = (str, type) => {
	var finalString = `-----BEGIN ${type.toUpperCase()} KEY-----\n`;

	while (str.length > 0) {
		finalString += str.substring(0, 64) + "\n";
		str = str.substring(64);
	}

	finalString = finalString + `-----END ${type.toUpperCase()} KEY-----`;

	return finalString;
};
