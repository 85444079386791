import {
	AccordionContent,
	Block,
	Button,
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	List,
	ListItem,
	Page,
	PageContent,
} from "framework7-react";
import React from "react";
import MaterialIcon from "../../components/misc/materialIcon";
import { useTranslation } from "react-i18next";
import "./style.css";

const ErrorPage = ({ error, resetErrorBoundary }) => {
	const { t } = useTranslation();
	const [accordionOpen, setAccordionOpen] = React.useState(false);

	return (
		<Page name="error-page" pageContent={false}>
			<PageContent className="flex items-center justify-center">
				<Card raised className="2xl:w-2/5 w-3/4">
					<CardHeader>
						<span className="text-[red]">
							<MaterialIcon
								icon="error"
								size={48}
								color="red"
								weight={500}
								className="align-sub"
							/>{" "}
							{t("ERROR")}
						</span>
					</CardHeader>
					<CardContent className="flex flex-col gap-2.5">
						<p className="text-2xl font-bold">{t("Something went wrong")}</p>
						<p className="text-lg">
							{t(
								"Please reload the app to keep using it. If the problem persists, please contact us with the error report."
							)}
						</p>
						<p className="text-lg">{t("Thank you.")}</p>
						<div className="mt-5 flex items-center justify-start gap-2.5">
							<Button round fill small onClick={resetErrorBoundary}>
								{t("Reload")}
							</Button>
							<Button
								round
								fill
                                small
                                className="external"
								onClick={() => {
									
								}}>
								{t("Contact support")}
							</Button>
						</div>
					</CardContent>
					<CardFooter>
						<List accordionList className="m-0 p-0 w-full">
							<ListItem
								accordionItem
								title={
									accordionOpen
										? t("Hide error detail")
										: t("View error detail")
								}
								onAccordionOpened={() => setAccordionOpen(true)}
								onAccordionClosed={() => setAccordionOpen(false)}>
								<AccordionContent>
									<Block className="bg-white rounded-md max-h-[50vh] overflow-auto">
										<pre className="whitespace-pre-wrap">{error.stack}</pre>
									</Block>
								</AccordionContent>
								{accordionOpen && (
									<Button
										slot="after"
										round
										fill
										small
										className="max-w-[100px]"
										onClick={resetErrorBoundary}>
										{t("Copy Error")}
									</Button>
								)}
							</ListItem>
						</List>
					</CardFooter>
				</Card>
			</PageContent>
		</Page>
	);
};

export default ErrorPage;
