import React, { useEffect, useRef } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import "./style.css";
import { useTranslation } from "react-i18next";

const DndContainer = (props) => {
	const { onDrop, setCompress } = props;
	const { t } = useTranslation();
	const dragHandle = useRef(null);

	const [{ canDrop, isOver }, drop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			drop(item) {
				if (onDrop) {
					const file = item.files[0];
					const isImageOrVideo =
						file.type.startsWith("image/") || file.type.startsWith("video/");
					if (isImageOrVideo) {
						setCompress(true);
					}
					onDrop(item);
				}
			},
			canDrop(item) {
				return true;
			},
			hover(item) {
			},
			collect: (monitor) => {
				const item = monitor.getItem();
				if (item) {
				}
				return {
					isOver: monitor.isOver(),
					canDrop: monitor.canDrop(),
				};
			},
		}),
		[props]
	);

	useEffect(() => {
		const onDragOver = (e) => {
			document.body.classList.add("user-is-dragging");
			clearTimeout(dragHandle.current);
			dragHandle.current = setTimeout(() => {
				document.body.classList.remove("user-is-dragging");
			}, 200);
        };
        
        document.body.addEventListener("dragover", onDragOver);
        
		return () => {
			document.body.removeEventListener("dragover", onDragOver);
		};
    }, []);
    
	return (
		<div draggable={true} ref={drop} className="dnd-container">
			<div className="border-4 border-white border-dashed w-full h-full text-center flex flex-col justify-center">
				<span className="text-body text-lg">
					{canDrop && isOver ? t("Release to drop") : t("Drag file here")}
				</span>
			</div>
		</div>
	);
};

export default DndContainer;
