import React, { useState, useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
const CallTimer = ({ membersCount }) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  const formatTime = () => { 
    if(isRunning) {
      return `${hours ? hours+":" : ""}${minutes < 10 ? "0" : ""}${minutes}:${
				seconds < 10 ? "0" : ""
			}${seconds}`;
    }else{
      return '';
    }
    
  };
  return (
		<span
			className={`call-timer ${
				membersCount !== 0 ? "text-white" : "text-body"
			} font-medium text-[13px] leading-4`}>
			{formatTime()}
		</span>
	); 
};

export default CallTimer;
