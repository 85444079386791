import { f7 } from "framework7-react";
import { checkVersion } from "./functions";
import { UPDATE_URL } from "../constants";
import { get } from "../axios/axiosClient";

export const updateSW = () => {
	if (navigator.serviceWorker && navigator.serviceWorker.controller) {
		navigator.serviceWorker.ready
			.then((worker) => {
				worker.update().then(() => {
					window.location.reload(true);
				});
			})
	} else window.location.reload(true);
};

export const clearcache = () => {
	caches
		.keys()
		.then(function (entries) {
			for (let entry of entries) caches.delete(entry);
			updateSW();
		})
		.catch(() => {
			updateSW();
		});
};

export const updateApp = async () => {
	if (f7.device.electron) {
		// write electron specific update check code here
	} else {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await get(UPDATE_URL, { req_id: "updateApp" });
				let storedVersion = localStorage.getItem("appVersion");
				if (storedVersion == null) {
					storedVersion = "1.0.0";
				}
				checkVersion(response.currentVersion, storedVersion) > 0
					? resolve({ ...response, storedVersion })
					: resolve(false);
			} catch (ex) {
				reject(ex);
			}
		});
	}
};
