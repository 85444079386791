import React from "react";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from "../../assets/images/icons/Forgot.png";
import Vector from "../../assets/images/icons/Vector.svg";
import email from "../../assets/images/icons/email.svg";
import LazyLoaderWraper from "../../components/misc/lazyloaderWraper";
import { useTranslation } from "react-i18next";



import {
	Page,
	Block,
	Button,
	PageContent,
	Link,
} from "framework7-react";
import "./style.css";
import MaterialIcon from "../../components/misc/materialIcon";

const ForgetPasswordPage = () => {
	const { t, i18n } = useTranslation();
	return (
		<Page name="ForgetPasswordPage" pageContent={false}>
			<AuthNavbar />

			<PageContent className="md:overflow-hidden main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[400px] ml-[30px]  md:w-[400px] pb-[15px]">
							<p className="flex items-center justify-start text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
								<Link back>
									<MaterialIcon icon="arrow_back_ios" size={32} />
								</Link>
								{t("Forgot Password ?")}
							</p>
							<p className="leading-5 text-bases text-color-[--f7-chat-heading-color] font-[--f7-font-family]">
								{t("Don't worry! it happend,please pick one of the option to recover your password.")}
							</p>
						</div>
						<Button
							href="/Viaemail/"
							rounded
							outline
							className="w-[400px] h-[62px] p-[20px] font-semibold my-[10px] outline-theme border-gray-300"
						>
							<img src={email} className="p-[8px] w-[42px] h-[42px]" />
							<span className="leading-5 text-xl  font-[--f7-font-family] text-color-[--f7-heading-color] text-color">
								{t("Via email")}
							</span>
						</Button>
						<Button
							href="/ViaPIN/"
							rounded
							outline
							className="w-[400px] h-[62px] p-[20px] font-semibold my-[10px] outline-theme border-gray-300"
						>
							<img src={Vector} className="p-[10px] w-[42px] h-[42px]" />
							<span className="leading-5 text-xl  font-[--f7-font-family] text-color-[--f7-heading-color] text-color">
								{" "}
								{t("Via SMS")}
							</span>
						</Button>
					</div>

					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<LazyLoaderWraper src={Forgot} alt="Icon" />
					</div>
				</Block>
			</PageContent>
			{/* Top Navbar */}
			{/* <Navbar large backLink="Back"></Navbar> */}

			{/* <Block>
        <p>This is a dynamic route id: {id}</p>
        <Button fill onClick={() => getLocationHandler()} disabled={isLoading}>
          {`${isLoading ? "Loading..." : "Fetch Location"}`}
        {console.log("isLoading",isLoading)}  
        </Button>
        <p className="break-word">{JSON.stringify(locationResponse)}</p>
      </Block> */}
		</Page>
	);
};
export default ForgetPasswordPage;
