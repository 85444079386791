import {
    Button,
    Link,
    List,
    ListInput,
    ListItem,
    Page,
    PageContent,
    Preloader,
    f7
  } from "framework7-react";
  import { Eye, EyeSlash, Lock, Profile } from "iconsax-react";
  import React, { useContext, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useUpdateEffect } from "react-use";
  import { AUTHTOKEN_COOKIE, STATUS_CODE } from "../../constants";
  import { loginwithPoshService } from "../../redux/features/authSlice/login";
  import { SocketContext } from "../../socket";
  import { setCookie } from "../../utils/functions";
  import Google from '../../assets/images/icons/G.svg'
  import Facebook from '../../assets/images/icons/F.svg'
  import Apple from '../../assets/images/icons/Apple.svg'
  import QR from '../../assets/images/icons/QR Code.png'
  import LazyLoaderWraper from "../../components/misc/lazyloaderWraper";
  import AuthNabvar from "../../components/navs/authNavbar";
  import { useTranslation } from "react-i18next";
  import Posh from "../../assets/images/icons/Posh.svg"
  const LoginWithPosh  = ({ f7router }) => {
    const dispatch = useDispatch();
    const socket = useContext(SocketContext)
  
    const { loginResponse, isLoading } = useSelector(
      (state) => state.loginSlice
    );
  
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isPhone, setIsPhone] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const[isValidEmail , setIsValidEmail]=  useState("");
    const[isValidPassword , setIsValidPassword]=  useState(true);
    const { t, i18n } = useTranslation();
    const signIn =  (e) => {
      // console.log("username", username,password)
      e.preventDefault();
      e.stopPropagation();
      dispatch(loginwithPoshService({ login: username , password: password }));
    };
  
    const handleEmailChange = (e) =>{
      const enteredEmail = e.target.value;
      setUsername(enteredEmail);
      setIsValidEmail(enteredEmail === "" || validateEmail(enteredEmail))
  
    }
    const validateEmail = (username) =>{
      const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      return emailPattern.test(username)
    }
    const handlePasswordChange = (e) =>{
      const enteredpassword = e.target.value;
      setPassword(enteredpassword);
     
      setIsValidPassword(enteredpassword === "" || validatePassword(enteredpassword))
  
    }
    const validatePassword = (password) => {
      const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
      return passwordPattern.test(password)
    }
  
  
    useUpdateEffect(() => {
      if (loginResponse.code === STATUS_CODE.SUCCESS) {
        setCookie(AUTHTOKEN_COOKIE, loginResponse.data.token);
        f7.emit("loginSuccess", loginResponse.data.id); //app's internal event for re-initializing socket with authtoken
        f7router.navigate(`${window.innerWidth < 775 ? "/chats/" : "/chats/null/single"}`);
      } else {
        //show error
        f7.toast.create({ text: loginResponse.message }).open();
      }
    }, [loginResponse]);
  
    return (
			<Page name="login" loginScreen pageContent={false}>
				<AuthNabvar />
				<PageContent className="flex mt-[40px] w-full px-[40px] md:px-0 justify-center flex-wrap gap-[100px] main-bg-bubble">
					<div className="w-full md:w-[400px]">
						<div className="pl-[30px]">
							<p className="text-[26px] font-boldtext-[--f7-heading-color-color]">
								{t("Login with WN Social Account")}
							</p>
							<p className="text-[18px]text-[--f7-heading-color-color]">
								{t("We are so excited to see you again!")}
							</p>
						</div>
						<List className="list-none">
							<ListInput
								type="text"
								placeholder={t("Enter email address")}
								className={`input-field-normal w-full ${
									username && !isValidEmail > 0 ? "invalid" : ""
								}`}
								value={username}
								onInput={(e) => {
									setUsername(e.target.value);
								}}
								required
								onChange={handleEmailChange}>
								<div slot="media">
									<Profile color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
							{username !== "" && !isValidEmail && (
								<p className=" ml-[40px] mt-[10px] error-text">
									{t("Please enter a valid email")} {t("gane@email.com")}
								</p>
							)}
						</List>
						<List>
							<ListInput
								type={passwordType}
								placeholder={t("Enter password")}
								className={`input-field-normal mt-5 ${
									password && !isValidPassword ? "invalid" : ""
								}`}
								value={password}
								onInput={(e) => {
									setPassword(e.target.value);
								}}
								required
								onChange={handlePasswordChange}>
								<div slot="content-end" className=" cursor-pointer">
									{passwordType === "password" ? (
										<Eye
											onClick={() => setPasswordType("text")}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									) : (
										<EyeSlash
											onClick={() => setPasswordType("password")}
											color="#5d6980"
											variant="Bold"
											size={24}
										/>
									)}
								</div>
								<div slot="media">
									<Lock color="#5d6980" variant="Bold" size={22} />
								</div>
							</ListInput>
							{password !== "" && !isValidPassword && (
								<p className="w-[345px] ml-[40px] mt-[10px] error-text">
									{" "}
									{t("Please enter the correct password")}{" "}
								</p>
							)}
						</List>
						<List>
							<ListItem className="mt-5 button-container mr-[-15px]">
								<Button
									type="submit"
									fill
									className="w-full"
									onClick={signIn}
									disabled={!username.length || !password.length || isLoading}>
									{isLoading ? <Preloader color="white" /> : t("Sign in")}
								</Button>
							</ListItem>
						</List>
						<List>
							<ListItem className="mr-[-25px] mt-4 pl-1">
								<div className="w-full flex mr-[-15px] items-center gap-2">
									<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-40" />
									<p className="text-[14px] text-[--f7-chat-heading-color]">
										{" "}
										{t("OR SIGN IN WITH")}
									</p>
									<div className="flex-1 h-[1px] bg-[--f7-body-text] opacity-40" />
								</div>
							</ListItem>

							<ListItem className="mr-[-30px] pl-1">
								<div className=" w-full flex justify-between">
									<span className="cursor-pointer">
										<LazyLoaderWraper src={Google} alt="Icon" />
									</span>
									<span className="cursor-pointer">
										<LazyLoaderWraper src={Apple} alt="Icon" />
									</span>
									<span>
										<LazyLoaderWraper src={Facebook} alt="Icon" />
									</span>
									<span className="cursor-pointer">
										<Link href="/LoginWithPosh/">
											<LazyLoaderWraper src={Posh} alt="Icon" />
										</Link>
									</span>
								</div>
							</ListItem>

							<ListItem className="mr-[-30px] mt-4 pl-1">
								<div className=" w-full flex justify-center">
									<p className="text-[16px]  text-[--f7-body-text]">
										<span className="opacity-70">
											{" "}
											{t("Don't have an account?")}{" "}
										</span>
										<Link className="text-[--f7-md-primary]" href="/signup">
											{" "}
											{t("Sign up")}
										</Link>
									</p>
								</div>
							</ListItem>
						</List>
					</div>
					<div className="w-full md:w-[400px] flex flex-col items-center justify-center">
						<LazyLoaderWraper src={QR} alt="Icon" />
						<div className="pl-[30px] mt-7 text-center">
							<p className="text-[26px] font-boldtext-[--f7-heading-color-color]">
								{" "}
								{t("Sign in with QR Code")}
							</p>
							<p className="text-[18px] mt-2text-[--f7-heading-color-color]">
								{t(
									"Scan this with the KT mesenger mobile app to sgin in instantly"
								)}
							</p>
						</div>
					</div>
				</PageContent>
			</Page>
		);
  };
  
  export default LoginWithPosh;
  