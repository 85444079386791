import { Button, List, ListItem } from "framework7-react";
import { Pause, Play, VolumeCross, VolumeHigh } from "iconsax-react";
import React, { useState } from "react";
import { defaultProfileIcon } from "../../utils/icons";
import MaterialIcon from "../misc/materialIcon";
import MoreMenu from "./MoreMenu";
import { db } from "../../js/db";
import { useLiveQuery } from "dexie-react-hooks";

function StoryHeader({
  playPausehandler,
  isPaused,
  translate,
  info,
  storyCreateTime,
  profile,
  storyId,
}) {
  const [isMute, setIsMute] = useState(false);
  const handleToggleMute = () => {
    const videoElement = document.querySelector(".videoParent video");
    if (videoElement) {
      videoElement.muted = !videoElement.muted;
      setIsMute(videoElement.muted);
    }
  };
  const users = useLiveQuery(() => db.members.toArray(), [], []);

  return (
    <div className="w-full mt-[1.5rem] absolute top-0 z-[3] h-[auto] select-none">
      <List mediaList className="px-[0.75rem] m-0">
        <ListItem>
          <div slot="media">
            <img
              className="w-[45px] h-[45px] rounded-[50%]"
              src={info?.avatar || defaultProfileIcon}
              alt="abc"
            />
          </div>
          <div slot="title">
            <p className="text-[#FFF] mt-1">
              {users.find((user) => user.id === info.id)?.nickname ||
                info.userName}
            </p>
          </div>
          <div slot="subtitle">
            <p className="text-[#FFF] text-[13px] font-normal">
              {storyCreateTime || ""}
            </p>
          </div>
          <div
            slot="after"
            className="flex h-[45px] cursor-pointer absolute top-0 right-0 gap-2 z-[2]"
          >
            <Button
              className={` bg-[#494949] relative z-[398798] w-[45px] h-[100%] cursor-pointer`}
              onClick={playPausehandler}
            >
              {isPaused ? (
                <Play size="25" color="#fff" variant="Bold" />
              ) : (
                <Pause size="25" color="#fff" variant="Bold" />
              )}
            </Button>
            <Button
              className={` bg-[#494949] w-[45px] h-[100%] cursor-pointer`}
              onClick={handleToggleMute}
            >
              {!isMute ? (
                <VolumeHigh size="25" color="#FFF" variant="Bold" />
              ) : (
                <VolumeCross size="25" color="#FFF" variant="Bold" />
              )}
            </Button>
            <Button
              className={` bg-[#494949] relative z-[3] w-[45px] h-[100%] cursor-pointer`}
              onClick={() => null}
              popoverOpen=".storyMenu"
            >
              <MaterialIcon
                icon="more_vert"
                color="#ffffff"
                className="text-[32px]"
              />
              <MoreMenu
                isMyStory={profile?.user_id === info?.id ? true : false}
                translate={translate}
                storyId={storyId}
              />
            </Button>
          </div>
        </ListItem>
      </List>
    </div>
  );
}

export default StoryHeader;
