import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	Panel,
	Tab,
	Tabs,
	Toolbar,
	f7,
} from "framework7-react";
import React, { useContext } from "react";
import MaterialIcon from "../../../../components/misc/materialIcon";
import FriendRequests from "../../tabs/friend-requests";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Mentions from "../../tabs/mentions";
import "./style.css";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";
import { db } from "../../../../js/db";

const Notifications = ({ onPanelClosed }) => {
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { t, i18n } = useTranslation();
	const socket = useContext(SocketContext);

	return (
		<Panel
			id="notification_panel"
			onPanelClosed={onPanelClosed}
			containerEl="#chats"
			left
			cover
			className="w-full rounded-none z-[998]">
			<Page
				name="notifications"
				className="pl-[var(--f7-toolbar-height)]"
				pageContent={false}>
				<Navbar className="notification-navbar">
					<NavLeft>
						<Link panelClose>
							<MaterialIcon icon="arrow_back" />
						</Link>
					</NavLeft>
					<NavTitle className="text-[--f7-heading-color] font-bold">
						{t("Notifications")}
					</NavTitle>
				</Navbar>
				<Toolbar
					tabbar
					className="mt-[60px] notification-toolbar pb-4 bg-[--f7-navbar-bg-color] notifications-toolbar">
					<Link tabLink="#friend_requests" tabLinkActive>
						{" "}
						{t("Friend Requests")}{" "}
					</Link>
					<Link tabLink="#mentions_tab">{t("Mentions")}</Link>
				</Toolbar>
				<Tabs className="mt-[100px]">
					<Tab
						tabActive
						id="friend_requests"
						onTabShow={() => {
							db.friendRequests
								.where("is_read")
								.equals(0)
								.modify((ref, value) => {
									ref.value = { ...value, is_read: 1 };
								});
						}}>
						<FriendRequests />
					</Tab>
					<Tab
						id="mentions_tab"
						onTabShow={() => {
							socket?.emit(
								ENDPOINTS.CLEAR_ALL_TAGS,
								JSON.stringify({
									user_id: loginResponse?.data?.user_id,
								}),
								(response) => {
									db.mentions
										.where("is_read")
										.equals(0)
										.modify((ref, value) => {
											ref.value = { ...value, is_read: 1 };
										});
									if (response) {
										f7.emit("clearMentions", response);
									}
								}
							);
						}}>
						<Mentions />
					</Tab>
				</Tabs>
			</Page>
		</Panel>
	);
};

export default Notifications;
