import {
	Link,
	NavLeft,
	NavTitle,
	Navbar,
	Toggle,
	Tab,
	List,
	ListItem,
} from "framework7-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialIcon from "../../../../../components/misc/materialIcon";
import useLocalStorageState from "use-local-storage-state";
import "./style.css";

const SettingNotification = () => {
	const { t } = useTranslation();
	const [textSound, setTextSound] = useState("bell");
	const [groupSound, setGroupSound] = useState("bell");
	const [groupMentionSound, setGroupMentionSound] = useState("bell");
	const [textNotification, setTextNotification] = useLocalStorageState(
		"text-notification",
		{ defaultValue: true }
	);
	const [reactionNotification, setReactionNotification] = useLocalStorageState(
		"reaction-notification",
		{ defaultValue: true }
	);
	const [groupTextNotification, setGroupTextNotification] =
		useLocalStorageState("group-text-notification", { defaultValue: true });
	const [groupReactionNotification, setGroupReactionNotification] =
		useLocalStorageState("group-reaction-notification", {
			defaultValue: true,
		});

	return (
		<Tab id="SettingNotification">
			<Navbar>
				<NavLeft>
					<Link tabLink="#settingsTab">
						<MaterialIcon
							size={48}
							icon="arrow_back"
							fill
							color="--f7-chat-heading-color"
						/>
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Notifications")}
				</NavTitle>
			</Navbar>
			<div className="page-content pt-0.5 flex flex-col items-center justify-start ">
				<div className="px-[30px] py-[15px] w-[95%] h-[auto] rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px] mx-[20px] flex gap-[10px] flex-col">
					<div className="flex justify-between">
						<p className="text-[16px] font-[700] text-[--f7-heading-color]">
							{t("Message")}
						</p>
					</div>
					<List className="mt-[20px] mb-0">
						<ListItem
							title={t("Notification tone")}
							className="setting-select"
							smartSelect
							smartSelectParams={{
								openIn: "popover",
								closeOnSelect: true,
								scrollToSelectedItem: true,
							}}>
							<select
								name="notification_tone"
								value={textSound}
								onChange={(e) => setTextSound(e.target.value)}>
								<option value="bell">Bell</option>
							</select>
						</ListItem>
					</List>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Text messages notifications")}
									</p>
									<Toggle
										checked={textNotification}
										onChange={() => {
											setTextNotification(!textNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show previews of notifications at the top of the screen")}
								</p>
							</div>
						</div>
					</div>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Reaction notifications")}
									</p>
									<Toggle
										checked={reactionNotification}
										onChange={() => {
											setReactionNotification(!reactionNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show notifications for reactions to messages you send")}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="px-[30px] py-[15px] w-[95%] h-[auto] rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px] mx-[20px] mb-[15px] flex gap-[10px] flex-col">
					<div className="flex justify-between">
						<p className="text-[16px] font-[700] text-[--f7-heading-color]">
							{t("Group chats")}
						</p>
					</div>
					<List className="mt-[20px] mb-0">
						<ListItem
							title={t("Notification tone")}
							className="setting-select"
							smartSelect
							smartSelectParams={{
								openIn: "popover",
								closeOnSelect: true,
								scrollToSelectedItem: true,
							}}>
							<select
								name="notification_tone"
								value={groupMentionSound}
								onChange={(e) => setGroupMentionSound(e.target.value)}>
								<option value="bell">Bell</option>
							</select>
						</ListItem>
						<ListItem
							title={t("Notification tone for mentions")}
							className="setting-select"
							smartSelect
							smartSelectParams={{
								openIn: "popover",
								closeOnSelect: true,
								scrollToSelectedItem: true,
							}}>
							<select
								name="notification_tone_mention"
								value={groupSound}
								onChange={(e) => setGroupSound(e.target.value)}>
								<option value="bell">Bell</option>
							</select>
						</ListItem>
					</List>
					<div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Group chats notifications")}
									</p>
									<Toggle
										checked={groupTextNotification}
										onChange={() => {
											setGroupTextNotification(!groupTextNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show previews of notifications at the top of the screen")}
								</p>
							</div>
						</div>
					</div>
					{/* <div className="w-full rounded-[20px] bg-[--f7-navbar-bg-color] mt-[20px]">
						<div className="flex items-start gap-[20px]">
							<div>
								<div className="flex justify-between">
									<p className="text-[16px] font-[400] text-[--f7-heading-color]">
										{t("Reaction notifications")}
									</p>
									<Toggle
										checked={groupReactionNotification}
										onChange={() => {
											setGroupReactionNotification(!groupReactionNotification);
										}}
									/>
								</div>
								<p className="text-[14px] font-[400] mt-[10px] text-[--f7-chat-heading-color]">
									{t("Show notifications for reactions to messages you send")}
								</p>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</Tab>
	);
};

export default SettingNotification;
