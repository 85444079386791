import { Block, Button } from "framework7-react";
import { Music, Sticker, Tag } from "iconsax-react";
import React from "react";
import { useTranslation } from "react-i18next";
import useCanvasHelper from "../../../hooks/useCanvasHelper.js";
import { addFiltersIcon } from "../../../utils/icons.js";
import DownloadIconSvg from "../../../utils/svgComponents/DownloadIconSvg.jsx";
import MaterialIcon from "../../misc/materialIcon/index.jsx";
import AddAudioMenu from "../menus/AddAudioMenu.jsx";
import AddDoodlesMenu from "../menus/AddDoodlesMenu.jsx";
import AddFilterMenu from "../menus/AddFilterMenu.jsx";
import AddStickerMenu from "../menus/AddStickerMenu.jsx";
import AddTextMenu from "../menus/AddTextMenu.jsx";

function VideoStoryTabs() {
  const { t: translate } = useTranslation();
  const {
    addtext,
    addImage,
    enableDrawingMode,
    disableDrawingMode,
    enableEraserMode,
    saveImage,
  } = useCanvasHelper();
  const tabs = [
		{
			image: (
				<MaterialIcon
					className={"text-[30px]"}
					icon="text_fields"
					fill
					color="#5D6980"
				/>
			),
			text: translate("Add text"),
			onClick: addtext,
		},
		// {
		//   image: (
		//     <Sticker
		//       style={{ rotate: "280deg" }}
		//       variant="Outlined"
		//       size="24"
		//       className=" transform"
		//       color="#5D6980"
		//     />
		//   ),
		//
		//   text: translate("Add stickers"),
		// },
		// {
		//   image: <img className="w-[24px] h-[24px]" src={addFiltersIcon} alt="" />,
		//
		//   text: translate("Add filter"),
		//   onClick: saveImage,
		// },
		{
			image: (
				<MaterialIcon
					className={"text-[30px]"}
					icon="gesture"
					fill
					color="#5D6980"
				/>
			),
			text: translate("Add doodles"),
		},
		// {
		//   image: <Music size="24" color="#5D6980" variant="Bold" />,
		//
		//   text: translate("Add audio"),
		// },
		// {
		//   image: <Tag size="24" color="#5D6980" variant="Bold" />,
		//
		//   text: translate("Tag people"),
		// },
		// {
		//   image: <DownloadIconSvg stroke={"#5D6980"} />,
		//
		//   text: translate("Download story"),
		// },
	];
  return (
    <Block className="pt-[12px] flex flex-col flex-1 overflow-scroll relative">
      {tabs.map((tab, index) => {
        return (
          <Button
            key={index}
            className="flex items-center justify-start rounded-[8px] px-[1rem] py-[2rem]"
            popoverOpen={
              index === 0
                ? ".addTextMenuOs2"
                : index === 2
                ? ".addStickerMenuOs4"
                : index === 3
                ? ".addFilterMenuOs3"
                : index === 1
                ? ".AddDoodlesMenuOs6"
                : index === 4
                ? ".addAudioMenuOs5"
                : ""
            }
            onClick={tab.onClick ? tab.onClick : () => null}
          >
            <Block className="w-[45px] h-[45px] bg-[#F1F1F1] rounded-[50%] flex justify-center items-center">
              {tab.image}
            </Block>
            <p className="ml-[1rem] text-[18px] font-semibold text-[#302F36]">
              {tab.text}
            </p>
          </Button>
        );
      })}
      <AddTextMenu />
      <AddStickerMenu onStickerSelect={addImage} />
      <AddFilterMenu />
      <AddDoodlesMenu
        enableDrawingMode={enableDrawingMode}
        disableDrawingMode={disableDrawingMode}
        enableEraserMode={enableEraserMode}
      />
      <AddAudioMenu />
    </Block>
  );
}

export default VideoStoryTabs;
