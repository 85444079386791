import { Block } from "framework7-react";
import React from "react";

function EmptyBlock({ translate }) {
  return (
    <Block className="w-[100%] min-h-[100%] bg-[#1F2634] flex justify-center items-center m-0 p-0">
      <p className=" text-[#FFF] text-[24px] text-center leading-[47px] font-semibold max-w-[346px]">
        {translate("Click on a contact story to view their story update")}
      </p>
    </Block>
  );
}

export default EmptyBlock;
