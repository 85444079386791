import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";
import { encrypt } from "../../../utils/crypto";

const initialState = {
	allConversationsResponse: {},
	isLoading: false,
	error: false,
};

export const conversationSlice = createSlice({
	name: "conversation",
	initialState,
	reducers: {
		saveConversationResponse: (state, action) => {
			state.allConversationsResponse = action.payload;
		},
		resetConversationResponse: (state) => {
			state.allConversationsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(conversationService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(conversationService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.allConversationsResponse = action.payload;
				// storing chat in index db
				db.chats
					.bulkPut(action?.payload?.data)
					.catch(Dexie.BulkError, function (err) {
					});
			})
			.addCase(conversationService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const conversationService = createAsyncThunk(
	"chat/getAllConversationService",
	async (values) => {
		if (!values) return;

		return post("/chat/conversations", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { saveConversationResponse, resetConversationResponse } =
	conversationSlice.actions;
export { conversationService };

export default conversationSlice.reducer;
