import React, { useEffect} from "react";
import kt_logo from "../assets/images/placeholders/kt_logo.svg";
import { Progressbar } from "framework7-react";
import { useTranslation } from "react-i18next";

const Splash = ({ children }) => {
    const { t } = useTranslation();

	useEffect(() => {
		const checkChildNodes = () => {
			const appEl = document.getElementById("app");
			if (appEl && appEl.hasChildNodes()) {
				document.getElementById("spalshScreen").remove();
				return true;
			}
			return false;
		};

		const intervalId = setInterval(() => {
			if (checkChildNodes()) {
				clearInterval(intervalId);
			}
		}, 100);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<>
			<div
				id="spalshScreen"
				className="fixed top-0 left-0 bottom-0 right-0 bg-white z-[99999] flex flex-col gap-5 justify-center items-center">
				<img src={kt_logo} className="max-w-[158px] mb-[48px]" />
				<span className="text-[#263238] text-xl text-center font-gelar">
					KT Messenger Web
				</span>
				<Progressbar infinite className="w-[250px]" />
			</div>
			{children}
		</>
	);
};

export default Splash;
