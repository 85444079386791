import React, { useContext, useEffect, useMemo, useState } from "react";
import {
	Badge,
	Button,
	Link,
	List,
	ListButton,
	NavRight,
	NavTitle,
	Navbar,
	Popover,
	f7,
	Popup,
	Page,
	Block,
} from "framework7-react";
import { useDispatch, useSelector } from "react-redux";
import LazyLoaderWraper from "../../misc/lazyloaderWraper";
import { MessageAdd1, Notification } from "iconsax-react";
import userimg from "../../../assets/images/placeholders/user.png";
import { deleteCookie } from "../../../utils/functions";
import MaterialIcon from "../../misc/materialIcon";
import { AUTHTOKEN_COOKIE } from "../../../constants";
import { db } from "../../../js/db";
import { logoutService } from "../../../redux/features/authSlice/logout";
import { useUpdateEffect, useWindowSize } from "react-use";
import { persistor } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { useLiveQuery } from "dexie-react-hooks";
import "./style.css";
import { SocketContext } from "../../../socket";
import { pendingRequestsService } from "../../../redux/features/userSlice/pendingRequests";
import { mentionsService } from "../../../redux/features/userSlice/mentions";
import AI from "../../../assets/images/icons/AI.svg";
import { Magicpen } from "iconsax-react";
import ExternalPagePopup from "../../misc/externalPagePopup";

const HomeNavbar = ({ toggleNewChatState }) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { width } = useWindowSize();
	const isMobile = useMemo(() => width < 775, [width]);
	const { loginResponse } = useSelector((state) => state.loginSlice);
	const { mentionsResponse } = useSelector((state) => state.mentionsSlice);
	const { pendingRequestsResponse } = useSelector(
		(state) => state.pendingRequestsSlice
	);
	const { logoutResponse } = useSelector((state) => state.logoutSlice);
	const socket = useContext(SocketContext);
	const userData = useLiveQuery(() =>
		db.users.where({ id: loginResponse?.data?.id }).first()
	);
	const user = userData || loginResponse?.data;
	const [notificationCount, setNotificationCount] = useState(0);
	const [popupOpened, setPopupOpened] = useState(false);
	const poshAIExists = useLiveQuery(
		() => db.chats.where({ chat_id: "posh_ai" }).first(),
		[],
		null
	);
	const mentions = useLiveQuery(() => db.mentions.toArray());
	const friendRequests = useLiveQuery(() => db.friendRequests.toArray());

	const [privacyOpened, setPrivacyOpened] = useState(false);
	const [termsOpened, setTermsOpened] = useState(false);

	useUpdateEffect(() => {
		if (logoutResponse?.code == 200 || logoutResponse?.code == 401) {
			socket.disconnect();
			deleteCookie(AUTHTOKEN_COOKIE);
			sessionStorage.removeItem("encryptionKey");
			localStorage.removeItem("keys");
			persistor.purge();
			db.delete()
				.then(() => {
				})
				.catch((err) => {
					console.error("Could not delete database");
				})
				.finally(() => {
					window.location.reload(true);
				});
		}
	}, [logoutResponse]);

	const callNotificationApis = () => {
		dispatch(pendingRequestsService({ user_id: user.id }));
		dispatch(mentionsService({}));
		setNotificationCount(0);
	};

	useUpdateEffect(() => {
		const unreadMentions = mentions?.filter((m) => m.is_read === 0).length || 0;
		const unreadRequests =
			friendRequests?.filter((m) => m.is_read === 0).length || 0;
		setNotificationCount(unreadMentions + unreadRequests);
	}, [mentions, friendRequests]);

	useEffect(() => {
		f7?.on("clearMentions", () => setNotificationCount(0)); //later calculate from mentions and friend requests

		return () => {
			f7?.off("clearMentions");
		};
	}, []);
	const markAsRead = async (mentionId) => {
		await db.mentions.update(mentionId, { is_read: 1 });
		setNotificationCount((prevCount) => (prevCount !== 0 ? prevCount - 1 : 0));
	};
	const openPopup = () => {
		setPopupOpened(true);
	};

	const closePopup = () => {
		setPopupOpened(false);
	};
	const createAIChat = async () => {
		const unixTime = Date.now();
		const updatedAt = new Date().toISOString();
		db.users
			.put({
				blocked: 0,
				email: "ai@posh.com",
				hide_phone: false,
				hide_profile_picture: false,
				id: "posh_ai",
				kalam_name: "Posh AI",
				kalam_number: "",
				name: "Posh AI",
				number: "",
				profile_image: "/static/poshai.svg",
				username: "posh_ai",
			})
			.then((id) =>{})
			.catch((ex) => console.error(ex));
		db.chats
			.put({
				is_muted: 0,
				chat_id: "posh_ai",
				type: "single",
				duration: 0,
				nickname: "Posh AI",
				is_read: 0,
				msg_type: "text",
				owner_id: "posh_ai",
				message_sender_id: 0,
				firstname: "Posh AI",
				hide_profile_picture: 0,
				profile_image: "/static/poshai.svg",
				last_message_id: null,
				allow_forwarding: 1,
				allow_sharing: 1,
				is_private_chat: 0,
				message: "Hi, I'm Posh AI. How can I help you today?",
				un_read_count: 0,
				unix_time: unixTime,
				updated_at: updatedAt,
				reaction: null,
				user_id: "posh_ai",
				disappearing_duration: null,
				accepted_by: null,
				calling_feature: 0,
				mobile: "",
				sender_id: "posh_ai",
				who_can_send_mes: "all",
			})
			.then((id) => {
				const messageObj = {
					allow_forwarding: 1,
					allow_sharing: 1,
					audio_text: "",
					audio_url: "",
					call_duration: 0,
					caption: "",
					chat_id: "posh_ai",
					duration: null,
					delivered: false,
					edited: 0,
					file: null,
					file_id: "",
					file_size: null,
					file_type: null,
					forwarded: 0,
					forwarded_at: null,
					forwarded_unix: "",
					group_files: [],
					group_id: "",
					id: unixTime,
					identifier: "posh_ai_welcome",
					is_group: false,
					is_private: 0,
					is_read: 2,
					language: "en",
					message: "Hi, I'm Posh AI. How can I help you today?",
					nickname: "Posh AI",
					original_audio_text: "",
					original_audio_url: "",
					original_caption: "",
					original_message: "Hi, I'm Posh AI. How can I help you today?",
					owner_id: "posh_ai",
					owner_name: "",
					parent_id: null,
					reactions: {
						like: 0,
						cry: 0,
						excited: 0,
						heart: 0,
						sad: 0,
						dislike: 0,
					},
					reply: "",
					reply_id: "",
					sender_id: "posh_ai",
					sender_name: "Posh AI",
					sequence: 1,
					story: null,
					thumbnail: "",
					type: "text",
					unix_time: unixTime,
				};

				db.messages
					.put(messageObj)
					.then((id) => {
					})
					.catch((ex) => {
						console.error(ex);
					});
				closePopup();
				if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
					f7.views.main.router.navigate(`/chats/null/single`);
				f7.store.dispatch("setActiveConversation", "posh_ai");
				f7.store.dispatch("setActiveConversationType", "single");
			})
			.catch((ex) => {
				console.error(ex);
				reject(ex);
			});
	};

	return (
		<Navbar>
			<NavTitle>
				<Link tabLink="#profileTab">
					<LazyLoaderWraper
						src={user?.profile_image}
						placeholder={userimg}
						height={39}
						width={39}
						alt=""
						className="rounded-full align-bottom object-cover"
						wrapperclassname="rounded-full align-bottom"
					/>
				</Link>
			</NavTitle>
			<NavRight>
				<Button
					className="h-[40px]"
					onClick={() => {
						if (!poshAIExists) openPopup();
						else {
							try {
								if (isMobile && f7.router.currentRoute.path !== "/chats/null/single")
									f7.views.main.router.navigate(`/chats/null/single`);
								f7.store.dispatch("setActiveConversation", "posh_ai");
								f7.store.dispatch("setActiveConversationType", "single");
							} catch (e) {
							}
						}
					}}>
					<LazyLoaderWraper
						src={AI}
						placeholder={AI}
						height={22.36}
						width={22.36}
						alt=""
						className="rounded-full align-bottom object-cover"
						wrapperclassname="rounded-full align-bottom"
					/>
				</Button>
				<Button onClick={toggleNewChatState} className="h-[40px]">
					<MessageAdd1 size="20" color="#5d6980" variant="Outline" />
				</Button>
				<Button
					panelOpen="#notification_panel"
					className="h-[40px]"
					onClick={async () => {
						await callNotificationApis();
						db.friendRequests.where({ is_read: 0 }).modify((request) => {
							request.is_read = 1;
						});
						// const firstUnreadMention = mentions?.find((m) => m.is_read === 0);
						// if (firstUnreadMention) {
						// 	await markAsRead(firstUnreadMention.id);
						// }
					}}>
					<Notification size="20" color="#5d6980" variant="Outline" />
					{notificationCount !== 0 && (
						<Badge
							color="#0183e2"
							className={`absolute ${
								notificationCount < 10
									? "top-[7px] right-[7px]"
									: "top-[4px] right-[2px]"
							} text-[10px] px-1 ${
								notificationCount < 10 ? "py-[0.425rem]" : "py-[0.61rem]"
							} font-normal h-[13px] min-w-[13px]`}>
							{notificationCount < 10 ? notificationCount : "9+"}
						</Badge>
					)}
				</Button>
				<Button popoverOpen=".setting-menu" className="h-[40px]">
					<MaterialIcon
						icon="more_vert"
						size={20}
						color="#5d6980"
						weight={400}
						grad={200}
					/>
				</Button>
			</NavRight>
			<Popover
				arrow={false}
				backdrop={false}
				className="setting-menu left-[185px]">
				<List>
					<ListButton
						text={t("Settings")}
						color=""
						popoverClose
						tabLink="#settingsTab"
					/>
					<ListButton
						text={t("Logout")}
						color="red"
						popoverClose
						onClick={() => {
							f7.dialog
								.create({
									title: t("Logout"),
									text: t(
										"Do you want to logout from all devices or this device only?"
									),
									buttons: [
										{
											text: t("Cancel"),
											color: "red",
											onClick: () => f7.dialog.close(),
										},
										{
											text: t("Logout from all devices"),
											onClick: function () {
												dispatch(logoutService({ all: 1 }));
											},
										},
										{
											text: t("Logout"),
											strong: true,
											color: "blue",
											keyCodes: [13],
											onClick: function () {
												dispatch(logoutService({ all: 0 }));
											},
										},
									],
								})
								.open();
						}}
					/>
				</List>
			</Popover>
			<Popup
				opened={popupOpened}
				onPopupClosed={closePopup}
				className="rounded-[20px] privacy-popup">
				<Page className="px-[15px] py-[25px]">
					<Button small className="absolute right-0 top-0" onClick={closePopup}>
						<MaterialIcon size={13} icon="close" color="#E3E3E3" />
					</Button>

					<div className="flex flex-col items-center justify-between gap-[20px]">
						<LazyLoaderWraper
							src={AI}
							placeholder={AI}
							height={115}
							width={115}
							alt=""
							className="rounded-full align-bottom object-cover"
							wrapperclassname="rounded-full align-bottom"
						/>
						<h2 className="text-[28px] font-normal leading-[38px]">
							{t("Ask Posh AI anything")}
						</h2>
						<Block className="text-left flex flex-col gap-[20px] my-0">
							<span className="flex">
								<Magicpen
									size="34"
									color="#0E99FE"
									className="text-blue-500 w-6 h-6 mr-2"
								/>

								<div className="text_aline">
									<strong className="text-lg font-semibold leading-[24.55px] text-left">
										{t("Get answers to all your questions")}
									</strong>
									<p className="text-base font-normal leading-[21.82px] text-left">
										{t(
											"Receive recommendations, find information, and learn new skills."
										)}
									</p>
								</div>
							</span>
							<span className="flex">
								<MaterialIcon size={34} icon="imagesmode" color="#0E99FE" />
								<div className="text_aline">
									<strong className="text-lg font-semibold leading-[24.55px] text-left">
										{t("Express yourself")}
									</strong>
									<p className="text-base font-normal leading-[21.82px] text-left">
										{t("Create unique images to share with anyone you like.")}
									</p>
								</div>
							</span>
							<span className="flex">
								<MaterialIcon
									fill
									size={34}
									icon="verified_user"
									color="#0E99FE"
								/>
								<div className="text_aline">
									<strong className="text-lg font-semibold leading-[24.55px] text-left">
										{t("Personal messages stay secure")}
									</strong>
									<p className="text-base font-normal leading-[21.82px] text-left">
										{t(
											"Posh AI may use your messages to enhance AI quality.Personal messages are never sent to Posh AI, can't be read,and remain end-to-end encrypted."
										)}
									</p>
								</div>
							</span>
						</Block>
						<p className="text-base font-normal leading-[21.82px] text-left">
							{t("Posh AI is an optional service. It is subject to Posh's")}
							<br />
							<Link
								href="#"
								className="text-blue-500"
								onClick={() => setPrivacyOpened(true)}>
								{t("Privacy Policy")}
							</Link>{" "}
							{t(".By tapping Continue, you agree to")}
							<br />
							<Link
								href="#"
								className="text-blue-500"
								onClick={() => setTermsOpened(true)}>
								{t("Posh AI's Terms")}
							</Link>
							.
							{/* <Link
								href="#"
								className="text-blue-500"
								onClick={() => setTermsOpened(true)}>
								Learn more
							</Link> */}
						</p>
						<Button
							className="max-w-[376px] w-full bg-blue-500 text-white"
							onClick={createAIChat}>
							{t("Continue")}
						</Button>
					</div>
				</Page>
				<ExternalPagePopup
					url="https://www.ktmessenger.com/legal/privacy.html"
					opened={privacyOpened}
					onClosed={() => setPrivacyOpened(false)}
				/>
				<ExternalPagePopup
					url="https://www.ktmessenger.com/terms.html"
					opened={termsOpened}
					onClosed={() => setTermsOpened(false)}
				/>
			</Popup>
		</Navbar>
	);
};

export default HomeNavbar;
