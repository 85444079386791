import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: null,
  text: "",
  bg: "#2196f3",
  color: "#FFF",
  fontSize: "Normal",
  textAlign: "c",
  selectedFile: null,
  curserSize: 10,
  brush: "balck",
  mode: null,
  shareURL: "",
  textColor: "#FFF",
};

const userStory = createSlice({
  name: "userStory",
  initialState,
  reducers: {
    updateStoryText: (state, action) => {
      state.text = action.payload;
    },
    updateStoryBG: (state, action) => {
      state.bg = action.payload;
    },
    updateStoryFontColor: (state, action) => {
      state.color = action.payload;
    },
    updateStoryFontSize: (state, action) => {
      state.fontSize = action.payload;
    },
    updateStoryType: (state, action) => {
      state.type = action.payload;
    },
    updateStorytextAlign: (state, action) => {
      state.textAlign = action.payload;
    },
    updateCurserSIze: (state, action) => {
      state.curserSize = action.payload;
    },
    updateDoodleColor: (state, action) => {
      state.doodleColor = action.payload;
    },
    updateMode: (state, action) => {
      state.mode = action.payload;
    },
    updateShareURL: (state, action) => {
      state.shareURL = action.payload;
    },
    updateTextColor: (state, action) => {
      state.textColor = action.payload;
    },
  },
});

export const {
  updateStoryText,
  updateStoryBG,
  updateStoryFontColor,
  updateStoryFontSize,
  updateStoryType,
  updateStorytextAlign,
  updateCurserSIze,
  updateDoodleColor,
  updateMode,
  updateTextColor,
  // updateShareURL
} = userStory.actions;

export default userStory.reducer;
