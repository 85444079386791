import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../js/db";
import { List, ListItem, SkeletonBlock } from "framework7-react";

const Linkify = ({ text, fontSize }) => {
  const metaDatas = useLiveQuery(() => db.metaDatas.toArray(), [], []);
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text?.split(urlRegex);
  const jsxContent = parts.map((part, index) => {
    if (part.match(urlRegex)) {
      const url = part;
      let urlParts = url.split("/");
      let finalURL = urlParts?.[0] + "//" + urlParts?.[2] + "/";
      const URLMetaData = metaDatas.find((meta) => meta?.url === finalURL);
      return (
        <div key={"linkify-"+index} className="relative z-[1000] flex flex-col w-full">
          {URLMetaData?.image && (
            <List
              mediaList
              style={{ background: "rgba(28,28,28,0.4)", cursor: "pointer" }}
            >
              <ListItem onClick={() => window.open(url, "_blank")}>
                {URLMetaData?.image ? (
                  <div style={{ width: 48, height: 48 }} slot="media">
                    <img
                      className="w-full h-full"
                      src={URLMetaData?.image}
                      alt=""
                    />
                  </div>
                ) : (
                  <SkeletonBlock
                    style={{ width: 48, height: 48 }}
                    slot="media"
                  />
                )}
                {URLMetaData?.title ? (
                  <div slot="title">
                    <p style={{ fontSize: 14, color: "#FFF" }}>
                      {URLMetaData?.title}
                    </p>
                  </div>
                ) : (
                  <SkeletonBlock
                    style={{ width: 300, height: 22 }}
                    slot="title"
                  />
                )}
                {URLMetaData?.url ? (
                  <div slot="subtitle">
                    <p
                      style={{
                        fontSize: 14,
                        color: "#FFF",
                        textAlign: "left",
                        marginTop: 6,
                      }}
                    >
                      {URLMetaData?.url}
                    </p>
                  </div>
                ) : (
                  <SkeletonBlock
                    style={{ width: 300, height: 22, marginTop: 4 }}
                    slot="subtitle"
                  />
                )}
              </ListItem>
            </List>
          )}
          <a
            key={index}
            href={url}
            style={{ fontSize: fontSize || 32, textDecoration: "underline" }}
            onClick={() => window.open(url, "_blank")}
            target="_blank"
            className="text-[32px] font-bold text-white break-all text-center w-full flex justify-center items-center"
            rel="noopener noreferrer"
          >
            {url}
          </a>
        </div>
      );
    } else {
      return (
				<span
					key={"linkify-" + index}
					className="text-[32px] font-bold text-white w-full break-all"
					style={{ fontSize: fontSize || 32, textAlign: "center" }}>
					{part}
				</span>
			);
    }
  });

  return (
    <div className="font-bold w-[100%] flex flex-col items-center  break-all overflow-hidden">
      {jsxContent}
    </div>
  );
};

export default Linkify;
