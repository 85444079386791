import React, { useContext, useEffect, useState } from "react";
import {
	Block,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	Preloader,
	f7,
	List,
	ListInput,
	Button,
	useStore,
} from "framework7-react";

import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import { useDispatch, useSelector } from "react-redux";
import groupimage from "../../../../assets/images/placeholders/group.png";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";
import { useUpdateEffect } from "react-use";
import { STATUS_CODE } from "../../../../constants";
import { getGroupDetailService } from "../../../../redux/features/chatSlice/groupDetail";
import { db } from "../../../../js/db";
import { useLiveQuery } from "dexie-react-hooks";
import imgcamera from "../../../../assets/images/icons/group-image.svg";
import "./style.css";
import { createOpenDialog, getFileType } from "../../../../utils/functions";
import { editGroupService } from "../../../../redux/features/chatSlice/editGroup";
import { uploadFileService } from "../../../../redux/features/chatSlice/uploadFile";
import { useTranslation } from "react-i18next";
const EditGroupInfo = ({ chat_id, switchMode }) => {
	chat_id = chat_id !== "posh_ai" ? parseInt(chat_id) : chat_id;
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const dispatch = useDispatch();
	const { getGroupDetailResponse } = useSelector(
		(state) => state.groupDetailSlice
	);
	const { uploadFileResponse } = useSelector((state) => state.uploadFileSlice);

	const { t, i18n } = useTranslation();
	const socket = useContext(SocketContext);
	const [groupData, setGroupData] = useState(false);
	const [muteChecked, setMuteChecked] = useState(false);
	const [groupName, SetGroupName] = useState(groupData?.group_name);
	const [groupDescription, SetGroupDescription] = useState("");
	const [selectedImage, setSelectedImage] = useState(groupData?.group_image);
	const [groupImage, setGroupImage] = useState({});
	const [switchToggle, setSwitchToggle] = useState(false);
	const [imgUrl, setImgUrl] = useState(null);

	useEffect(() => {
		setGroupData(false);
		setMuteChecked(false);
		SetGroupName(groupData?.group_name);
		SetGroupDescription("");
		setSelectedImage(groupData?.group_image);
		setGroupImage({});
		setSwitchToggle(false);
		setImgUrl(null);
	}, [chat_id]);
	// const [vlData, setVlData] = useState({
	// 	items: [],
	// });

	useEffect(() => {
		dispatch(getGroupDetailService({ chat_id }));
	}, [chat_id]);

	useUpdateEffect(() => {
		setGroupData(getGroupDetailResponse?.data?.[0]);
		SetGroupName(getGroupDetailResponse?.data?.[0]?.group_name);
		setGroupImage(getGroupDetailResponse?.data?.[0]?.group_image);
		// setMuteChecked(userProfileResponse?.data?.[0].is_mute);
		// setForwardChecked(userProfileResponse?.data?.[0].allow_forwarding);
	}, [getGroupDetailResponse]);

	const dateString = groupData?.created_at;
	const dateObject = new Date(dateString);
	const options = { year: "numeric", month: "long", day: "numeric" };
	const createdAt = dateObject.toLocaleDateString("en-US", options);

	// const renderExternal = (vl, newData) => {
	// 	setVlData({ ...newData });
	// };

	// const searchAll = (query, searchItems) => {
	// 	const found = [];
	// 	for (let i = 0; i < searchItems.length; i += 1) {
	// 		if (
	// 			searchItems[i].name
	// 				.toLowerCase()
	// 				.indexOf(query.toLowerCase()) >= 0 ||
	// 			query.trim() === ""
	// 		)
	// 			found.push(i);
	// 	}
	// 	return found;
	// };
	useUpdateEffect(() => {
		if (uploadFileResponse?.code === STATUS_CODE.SUCCESS) {
			const uploadedFile = uploadFileResponse.data[0].file_url;
			setImgUrl(uploadedFile);
		}
	}, [uploadFileResponse]);

	const attachFileHandler = (type = "image") => {
		const fileInput = createOpenDialog(type, "file", false);

		fileInput.onchange = (_) => {
			const file = fileInput.files[0];
			const url = URL.createObjectURL(file);
			setSelectedImage(url);
			setGroupImage({ url, file });
			uploadImage(file); // Call the function to upload the image
			fileInput.remove();
		};

		fileInput.click();
	};
	const uploadImage = async (file) => {
		// Use your actual uploadFileService action
		try {
			const formData = new FormData();
			formData.append("type", getFileType(file));
			formData.append("file", file);
			const response = await dispatch(uploadFileService(formData));
		} catch (error) {
			console.error("Error uploading image:", error);
		}
	};
	const members = useLiveQuery(async () => {
		if (!chat_id || chat_id === "null" || isNaN(chat_id)) return [];
		await db.members.where({ chat_id: chat_id }).toArray();
	}, [chat_id], []);

	useUpdateEffect(() => {
		if (switchToggle) {
			socket.emit(
				ENDPOINTS.TOGGLE_MUTE_NOTIFICATIONS,
				JSON.stringify({
					chat_ids: [chat_id],
					mute: muteChecked ? 1 : 0,
				}),
				(response) => {
					f7.emit("mute", response);
				}
			);

			setSwitchToggle(false);
		}
	}, [muteChecked]);

	const handleGroupName = (e) => {
		const groupName = e.target.value;
		SetGroupName(groupName);
	};
	const handleGroupDescription = (e) => {
		const groupDescription = e.target.value;
		SetGroupDescription(groupDescription);
	};
	const updateGroup = () => {
		dispatch(
			editGroupService({
				chat_id: chat_id, // Add the chat_id if needed
				group_name: groupName,
				group_image: imgUrl,
				add_group_members: [],
				remove_group_members: [],
			})
		);
		db.chats
			.where({ chat_id: chat_id })
			.modify({
				nickname: groupName,
				profile_image: imgUrl,
			})
			.then(() => {})
			.catch((error) => {
				console.error("Error updating chat:", error);
			});
		switchMode();
	};
	return groupData ? (
		<Page>
			<Navbar>
				<NavLeft>
					<Button onClick={switchMode}>
						<MaterialIcon size={48} icon="close" color="black" />
					</Button>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Edit group information")}
				</NavTitle>
			</Navbar>
			<Block className="flex pb-[25px]  my-0 pt-5 flex-col w-full items-center">
				<LazyLoaderWraper
					src={selectedImage || groupData?.group_image || groupimage}
					placeholder={groupimage}
					height={150}
					width={150}
					alt=""
					className="rounded-full align-bottom"
					wrapperclassname="rounded-full align-bottom"
				/>
				<div className="group-imge">
					<div className="flex justify-center items-center">
						<img
							src={imgcamera}
							onClick={() => {
								attachFileHandler();
							}}
						/>
					</div>
				</div>
				<p className="text-[28px] text-[--f7-heading-color] font-[700] pt-[20px] whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px] max-h-[70px]">
					{groupData?.group_name}
				</p>
			</Block>
			<Block>
				<p className="text-[14px] font-bold text-[--f7-chat-heading-color]">
					{t("Group Name")}
				</p>
				<List className="mt-[10px] mb-[32px]">
					<ListInput
						className="input-field-normal list-none Group"
						value={groupName}
						onInput={(e) => {
							SetGroupName(e.target.value);
						}}></ListInput>
				</List>
				<p className="text-[14px] font-bold text-[--f7-chat-heading-color]">
					{t("GROUP DESCRIPTION")}
				</p>
				<List className="mt-[10px] mb-[32px]">
					<ListInput
						className="input-field-normal list-none Group"
						onInput={(e) => {
							SetGroupDescription(e.target.value);
						}}></ListInput>
				</List>
				<Button
					text={t("Save")}
					rounded
					fill
					className="w-[315px] h-[62px] pa font-semibold"
					onClick={updateGroup}></Button>
			</Block>
		</Page>
	) : (
		<Page>
			<div className="flex flex-col justify-center items-center h-100 w-100">
				<Preloader />
			</div>
		</Page>
	);
};

export default EditGroupInfo;
