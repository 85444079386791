export const ENDPOINTS = Object.freeze({
	GET_BLOCK_LIST: "get_block_list",
	SEND_MESSAGE: "send_message",
	START_CHAT: "startChat",
	BLOCK_CONTACT: "block_contact",
	UNBLOCK_CONTACT: "unblock_contact",
	TOGGLE_MUTE_NOTIFICATIONS: "toggle_mute_notifications",
	SEND_REQUEST: "send_request",
	CANCEL_FRIEND_REQUEST: "cancel_friend_request",
	UPDATE_PROFILE_IMAGE: "update_profile_image",
	CLEAR_MESSAGES: "clear_messages",
	DELETE_CHATS: "delete_chats",
	DELETE_MESSAGE: "delete_message",
	ADD_GROUP_ADMIN: "add_group_admin",
	DELETE_GROUP_ADMINS: "delete_group_admins",
	CHECK_USER_STATUS: "check_user_status",
	READ_ALL_MESSAGES: "read_all_messages",
	EDIT_MESSAGE: "edit_message",
	EDIT_CAPTION: "edit_caption",
	ADD_GROUP_MESSAGE: "add_group_admin",
	FORWARD_MESSAGE: "forward_message",
	ADD_REACTION: "add_reaction",
	REMOVE_REACTION: "remove_reaction",
	START_NEW_CALL: "startNewCall",
	END_CALL: "reject",
	CALL_ANSWERED: "callAnswered",
	NEW_CALL_RECEIVED: "newcallreceived",
	TRANSLATE_MESSAGE: "translate_message",
	TOGGLE_DISAPPEARING_MESSAGES: "toggle_disappearing_messages",
	MESSAGE_DELIVERED: "message_delivered",
	MESSAGE_SEEN: "message_seen",
	VIEW_STORY: "view_story",
	DELETE_STORY: "delete_story",
	CHANGE_USER_STATUS: "change_user_status",
	GET_PRIVACY_SETTINGS: "get_privacy_settings",
	UPDATE_PRIVACY_SETTING: "update_privacy_setting",
	UPDATE_SETTINGS: "update_settings",
	GET_BLOCK_LIST: "get_block_list",
	CLEAR_ALL_TAGS:"clear_all_tags",
	LEAVE_GROUP:"leave_group"
});

export const LISTENERS = Object.freeze({
	GLOBAL: "message",
	UNAUTHORIZED: "unauthorized",
	USER_STATUS: "user_status",
	NEW_MESSAGE: "new_message",
	MESSAGE_DELETED: "message_deleted",
	EDIT_MESSAGE: "edit_message",
	EDIT_CAPTION: "edit_caption",
	MESSAGE_LANG_UPDATED: "message_language_updated",
	MESSAGE_DELIVERED: "message_delivered",
	MESSAGES_DELIVERED: "messages_delivered",
	ALL_MESSAGES_READ: "all_messages_read",
	SEEN_MESSAGE: "seen_message",
	REACTION: "reaction",
	MESSAGE_TYPING_GROUP: "message_typing_group",
	MESSAGE_STOP_TYPING_GROUP: "message_stop_typing_group",
	START_NEW_CALL: "startNewCall",
	NEW_CALL_RECEIVED: "newcallreceived",
	CALL_ANSWERED: "callAnswered",
	CALL_ENDED: "reject",
	CALL_ACCEPTED: "callaccepted",
	CONVERSATIONS_STATUS: "conversations_status",
	DELETE_CHATS:"delete_chats",
	CLEAR_MESSAGES:"clear_messages",
	BLOCK_CONTACT:"block_contact",
	UNBLOCK_CONTACT:"unblock_contact",
	
});
