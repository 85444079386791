import { Emoji } from "emoji-picker-react";
import {
	Button,
	Chip,
	Link,
	List,
	ListItem,
	Popover,
	Sheet,
	Tab,
	Tabs,
	Toolbar,
	f7,
} from "framework7-react";
import React, {
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { REACTIONS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getReactionDetailService } from "../../../redux/features/chatSlice/reactionDetail";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../js/db";
import "./style.css";
import { SocketContext } from "../../../socket";
import { ENDPOINTS } from "../../../constants/socket";
import { useNetworkState, useUpdateEffect, useWindowSize } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import userimage from "../../../assets/images/placeholders/user.png";

const MessageReactions = ({
	id,
	count,
	reactionPickerOpen,
	visible,
	setMyExistingReactions = null,
}) => {
	const dispatch = useDispatch();
	const netState = useNetworkState();
	const { width, height } = useWindowSize();
	const desktop = useMemo(() => width >= 775, [width]);
	const [reactions, setReactions] = useState(null);
	const socket = useContext(SocketContext);
	const [renderReaction, setRenderReaction] = useState(false);
	const [
		messageContext,
		setMessageContext,
		{ removeItem: removeMessageContext },
	] = useLocalStorageState("messageContext");

	const { loginResponse } = useSelector((state) => state.loginSlice);
	

	const _message = useLiveQuery(
		() => db.messages.where({ id: id }).first(),
		[],
		{}
	);

	const message = useMemo(() => _message, [_message]);

	

	useEffect(() => {
		const hasReaction =
			message?.reactions &&
			Object.values(message?.reactions).some(
				(value) => value === 1 || value.toString().length > 1
			);

		!hasReaction && !reactionPickerOpen && setRenderReaction(false);
	}, [message, reactionPickerOpen]);

	// useEffect(() => {
	// 	if (userReactions && userReactions.reactions) {
	// 		let _reactions = Object.keys(userReactions.reactions).filter(
	// 			(key) => userReactions.reactions[key].length > 0
	// 		);
	// 		let _allCount = 0;
	// 		_reactions.forEach((reaction) => {
	// 			_allCount += userReactions.reactions[reaction].length;
	// 		});
	// 		setReactions(_reactions);
	// 		setAllCount(_allCount);
	// 	}
	// }, [userReactions]);

	// const removeReaction = (reaction) => {
	// 	socket.emit(
	// 		ENDPOINTS.REMOVE_REACTION,
	// 		JSON.stringify({
	// 			message_id: message.id,
	// 			reaction: reaction,
	// 			chat_id: parseInt(message.chat_id),
	// 		}),
	// 		async (response) => {
	// 			if (response?.status) {
	// 				try {
	// 					await db.messages.where({ id: message.id }).modify((value, ref) => {
	// 						ref.value = {
	// 							...value,
	// 							reactions: { ...response.reactions },
	// 						};
	// 					});
	// 					dispatch(getReactionDetailService({ message_id: message.id }));
	// 					setMyExistingReactions &&
	// 						messageContext?.id === message.id &&
	// 						setMyExistingReactions((prev) =>
	// 							prev.filter((r) => r !== reaction)
	// 						);
	// 					removeMessageContext();
	// 				} catch (ex) {
	// 					console.log("Error in removing reaction", ex);
	// 				}
	// 			} else {
	// 				f7.toast.create({
	// 					text: "Something went wrong",
	// 					position: "center",
	// 					closeTimeout: 2000,
	// 				});
	// 			}
	// 		}
	// 	);
	// };

	useEffect(() => {
		if (visible && message?.reactions) {
			setTimeout(() => setRenderReaction(true), 100);
		} else {
			setRenderReaction((prev) => prev && !prev);
		}
	}, [visible, message]);

	return (
		<>
			<Button
				className={`h-auto -mt-3 z-[1111] ${!visible ? "hidden" : ""}`}
				{...(!desktop
					? { sheetOpen: `#reaction-${id}` }
					: { popoverOpen: `#reaction-popover` })}
				onClick={() => {
					setMessageContext(message);
					// f7.store.dispatch("setReactionContext", {
					// 	message_id: id,
					// 	allCount,
					// 	reactions,
					// 	userReactions,
					// 	messageReactions: message?.reactions || null,
					// });
					netState.online
						? dispatch(getReactionDetailService({ message_id: id }))
						: !reactions &&
						  f7.toast
								.create({
									text: "Please connect to the internet to see reaction details",
									position: "center",
									closeTimeout: 2000,
								})
								.open();
				}}>
				<Chip className="reaction-chip rounded-full bg-white h-[21px]">
					<div slot="text" className="flex">
						{renderReaction &&
							Object.keys(message?.reactions)
								.filter(
									(key) =>
										typeof message?.reactions[key] !==
											"string" &&
										message?.reactions[key] > 0
								)
								.map((reaction, index) => {
									return (
										<Emoji
											key={index}
											unified={REACTIONS[reaction]}
											size={16}
										/>
									);
								})}
						{count > 1 && (
							<span className="text-[12px] text-body font-[500] ml-1">
								{count}
							</span>
						)}
					</div>
				</Chip>
			</Button>
			{/* {!desktop && renderReaction && (
				<Sheet
					key={`reaction-${id}`}
					id={`reaction-${id}`}
					backdrop
					closeByBackdropClick
					closeByOutsideClick
					className="reaction-popover">
					<Toolbar tabbar scrollable>
						<Link
							tabLink="#reactiontab-0"
							tabLinkActive
							className="text-[16px] text-body w-[57px]">
							All {allCount}
						</Link>
						{reactions?.map((reaction, index) => {
							return (
								<Link
									key={index}
									tabLink={`#reactiontab-${index + 1}`}
									tabLinkActive={index === 1}
									className="flex-row justify-around w-[57px]">
									<Emoji unified={REACTIONS[reaction]} size={16} />
									<span className="text-[16px] text-body ml-1">
										{userReactions?.reactions[reaction].length}
									</span>
								</Link>
							);
						})}
					</Toolbar>

					<Tabs>
						<Tab
							id="reactiontab-0"
							tabActive
							className="h-[206px] overflow-auto">
							<List
								className={`${
									!reactions && "skeleton-text skeleton-effect-wave"
								}`}>
								{!reactions && (
									<ListItem
										title="Placeholder account name"
										footer="placeholder footer"
										media={userimage}></ListItem>
								)}
								{reactions?.map((reaction, index) => {
									{
										return userReactions?.reactions[reaction].map(
											(user, uindex) => {
												return (
													<ListItem
														key={index + uindex}
														title={user.firstname}
														// {...(parseInt(user.id) === loginResponse.data.id
														// 	? {
														// 			footer: "Click to remove",
														// 			className: "cursor-pointer",
														// 			onClick: () => {
														// 				f7.sheet.close();
														// 				setTimeout(() => {
														// 					removeReaction(reaction);
														// 				}, 200);
														// 			},
														// 	  }
														// 	: {})}
														media={user.profile_image}
														after={
															<Emoji unified={REACTIONS[reaction]} size={28} />
														}></ListItem>
												);
											}
										);
									}
								})}
							</List>
						</Tab>
						{reactions?.map((reaction, index) => {
							return (
								<Tab
									id={`reactiontab-${index + 1}`}
									key={index}
									className="h-[206px] overflow-auto">
									<List>
										{userReactions?.reactions[reaction].map((user, uindex) => {
											return (
												<ListItem
													key={index + uindex}
													title={user.firstname}
													// {...(parseInt(user.id) === loginResponse.data.id
													// 	? {
													// 			footer: "Click to remove",
													// 			className: "cursor-pointer",
													// 			onClick: () => {
													// 				f7.sheet.close();
													// 				setTimeout(() => {
													// 					removeReaction(reaction);
													// 				}, 200);
													// 			},
													// 	  }
													// 	: {})}
													media={user.profile_image}
													after={
														<Emoji unified={REACTIONS[reaction]} size={28} />
													}></ListItem>
											);
										})}
									</List>
								</Tab>
							);
						})}
					</Tabs>
				</Sheet>
			)} */}
		</>
	);
};

export default MessageReactions;
