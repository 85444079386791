import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";

import { encrypt } from "../../../utils/crypto";
const initialState = {
	deleteContactsResponse: {},
	isLoading: false,
	error: false,
};

export const deleteContactSlice = createSlice({
	name: "deleteContacts",
	initialState,
	reducers: {
		savedeleteContactsResponse: (state, action) => {
			state.deleteContactsResponse = action.payload;
		},
		resetdeleteContactsResponse: (state) => {
			state.deleteContactsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(deleteContactsService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(deleteContactsService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action.payload) return;
				state.deleteContactsResponse = action.payload;
			})
			.addCase(deleteContactsService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const deleteContactsService = createAsyncThunk(
	"/user/deleteContactSlice",
	async (values) => {
		if (!values) return;

		return post("/user/delete_contact", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { savedeleteContactsResponse, resetdeleteContactsResponse } =
	deleteContactSlice.actions;
export { deleteContactsService };

export default deleteContactSlice.reducer;
