import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import {
	Navbar,
	NavTitle,
	NavRight,
	Input,
	ListItem,
	List,
} from "framework7-react";
import logo from "../../../assets/images/logo/Kalamtime.svg";
import "./style.css";
import languages from "../../../constants/languages.json";

const AuthNavbar = () => {
    const { t, i18n } = useTranslation();
    
	return (
		<>
			{i18n && i18n?.language &&
				<Navbar className="auth-navbar">
					<NavTitle>
						<img src={logo} className="w-[58px] md:ml-[85px]" alt="Logo" />
					</NavTitle>
					<NavRight>
						<List className="language-list md:mr-[85px]">
							<ListItem
								after={languages[i18n?.language?.split("-")[0]]}
								smartSelect
								smartSelectParams={{
									openIn: "popover",
									closeOnSelect: true,
									inputIconPosition: "end",
									cssClass: "language-select",
									renderItem: (item, index) => {
										const title = languages[item.value][1];
										const subtitle = languages[item.value][0];
										return `<li class="">
                                    <label class="item-radio item-radio-icon-end item-content">
                                        <input type="radio" name="radio-21a8bfca5b" value="${
																					item.value
																				}" ${
											item.selected ? "checked" : null
										} />
                                        <i class="icon icon-radio"></i>
                                        <div class="item-inner" style="flex-direction: column;align-items: start; justify-content:center;">
                                            <div class="item-title">${title}</div>
                                            ${
																							subtitle !== title
																								? `<div class="item-footer">${subtitle}</div>`
																								: ""
																						}
                                        </div>
                                    </label>
                                </li>`;
									},
								}}>
								<select
									defaultValue={i18n?.language}
									onChange={(e) => i18n.changeLanguage(e.target.value)}>
									{Object.keys(languages).map((key) => (
										<option key={key} value={key}>
											{languages[key][1]}
										</option>
									))}
								</select>
							</ListItem>
						</List>
					</NavRight>
				</Navbar>
			}
		</>
	);
};

export default AuthNavbar;
