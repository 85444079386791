import React, { useState } from "react";
import { Page, Block, f7, Button, PageContent } from "framework7-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocation } from "../../redux/features/locationSlice/location";
import AuthNavbar from "../../components/navs/authNavbar";
import Forgot from "../../assets/images/icons/Forgot.png";
import SMSBOX from "../../assets/images/icons/SMSBOX.svg";
import { useTranslation } from "react-i18next";

import "./style.css";

const checkEmail = ({ id }) => {
	const dispatch = useDispatch();
	const deviceInfo = f7.device;
	const { t, i18n } = useTranslation();
	const [phoneNumber, setPhoneNumber] = useState("");
	const [username, setUsername] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [name, setName] = useState("");
	const { locationResponse, isLoading } = useSelector(
		(state) => state.locationSlice
	);
	const changeLocale = (locale) => {
		i18n.changeLanguage(locale);
	};

	const getLocationHandler = () => {
		const body = {
			login: "",
			password: "",
			device_type: "",
			version_code: "3.0.0",
			os_name: "",
		};
		const handleSubmit = (event) => {
			event.preventDefaule();
			alert(`the name you enter war here`);
		};

		const config = {
			method: "post",
			url: "/api/user/signin",
			data: body,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};

		dispatch(fetchLocation(config));
	};

	return (
		<Page name="checkEmail" pageContent={false}>
			<AuthNavbar />

			<PageContent className="md:overflow-hidden main-bg-bubble">
				<Block className="flex-auto flex flex-row flex-wrap items-center justify-center h-full">
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center">
						<div className="w-[400px] pb-[10px]">
							<p className="text-color-[--f7-heading-color] font-semiblod text-2xl leading-8 font-[--f7-font-family]">
								{t("Check your mail..")}
							</p>
							<p className="leading-5 text-bases font-normal text-color-[--f7-chat-heading-color] font-[--f7-font-family]">
								{t("We have sent password recovery instruction to your email")}
							</p>
						</div>
						<div className="flex flex-col items-center justify-center ">
							<img src={SMSBOX} className="w-[97px] h-[130px]" />
						</div>

						<Button
							href="/CreateNewPassword/"
							// href="/login/"
							fill
							className="w-[400px]">
							{isLoading ? <Preloader color="white" /> : t("Open email app")}
						</Button>
						<Button className="w-[400px]" href="/login/">
							{t("Skip I'll comfirm later")}
						</Button>
					</div>
					<div className="flex-[1_0_0] self-stretch flex flex-col items-center justify-center removed-the-image">
						<img src={Forgot} />
					</div>
				</Block>
			</PageContent>
		</Page>
	);
};
export default checkEmail;
