import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { db } from "../../../js/db";
import Dexie from "dexie";

const initialState = {
	loginResponse: {},
	isLoading: false,
	error: false,
};

const pendingHandler = (state) => {
	state.isLoading = true;
};
const fullfillmentHandler = (state, action) => {
	state.isLoading = false;
	if(!action.payload) return;
	state.loginResponse = action.payload;
	action.payload?.data?.id
		? (localStorage.setItem("uid", action.payload?.data?.id),
		  db.users
				.put(
					{ ...action?.payload?.data, ...action?.payload?.extra },
					{
						id: action?.payload?.data?.id,
					}
				)
				.then(async function (lastKey) {
				})
				.catch(Dexie.BulkError, function (err) {
				}))
		: (state.isLoading = false);
};

const rejectionHandler = (state, action) => {
	state.isLoading = false;
	state.error = action.error.message;
};

export const loginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		saveLoginResponse: (state, action) => {
			state.loginResponse = action.payload;
			state.error = false;
			state.isLoading = false;
		},
		resetLoginResponse: (state) => {
			state.loginResponse = {};
			state.error = false;
			state.isLoading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginService.pending, pendingHandler)
			.addCase(loginService.fulfilled, fullfillmentHandler)
			.addCase(loginService.rejected, rejectionHandler)
			.addCase(signupService.pending, pendingHandler)
			.addCase(signupService.fulfilled, fullfillmentHandler)
			.addCase(signupService.rejected, rejectionHandler)
			.addCase(loginwithPoshService.pending, pendingHandler)
			.addCase(loginwithPoshService.fulfilled, fullfillmentHandler)
			.addCase(loginwithPoshService.rejected, rejectionHandler)
			.addCase(autoLoginwithPoshService.pending, pendingHandler)
			.addCase(autoLoginwithPoshService.fulfilled, fullfillmentHandler)
			.addCase(autoLoginwithPoshService.rejected, rejectionHandler)
			.addCase(socialLoginService.pending, pendingHandler)
			.addCase(socialLoginService.fulfilled, fullfillmentHandler)
			.addCase(socialLoginService.rejected, rejectionHandler)
			.addCase(appleLoginService.pending, pendingHandler)
			.addCase(appleLoginService.fulfilled, fullfillmentHandler)
			.addCase(appleLoginService.rejected, rejectionHandler);
	},
});

const loginService = createAsyncThunk("auth/loginService", async (values) => {
	if (!values) return;

	let localKeys = localStorage.getItem("keys");
	let postData = values;
	if (localKeys) {
		localKeys = JSON.parse(localKeys);
		postData.public_key = localKeys.publicKey;
	}

	return post("/user/signin", {
		type: "application/x-www-form-urlencoded",
		data: postData,
	});
});
const signupService = createAsyncThunk("auth/signupService", async (values) => {
	if (!values) return;

	let localKeys = localStorage.getItem("keys");
	let postData = values;
	if (localKeys) {
		localKeys = JSON.parse(localKeys);
		postData.public_key = localKeys.publicKey;
	}

	return post("/user/signup_new", {
		type: "application/x-www-form-urlencoded",
		data: postData,
	});
});
const loginwithPoshService = createAsyncThunk(
	"auth/loginwithPoshService",
	async (values) => {
		if (!values) return;

		let localKeys = localStorage.getItem("keys");
		let postData = values;
		if (localKeys) {
			localKeys = JSON.parse(localKeys);
			postData.public_key = localKeys.publicKey;
		}

		return post("/user/posh_login", {
			type: "application/x-www-form-urlencoded",
			data: postData,
		});
	}
);
const autoLoginwithPoshService = createAsyncThunk(
	"auth/autoLoginwithPoshService",
	async (values) => {
		if (!values) return;

		let localKeys = localStorage.getItem("keys");
		let postData = values;
		if (localKeys) {
			localKeys = JSON.parse(localKeys);
			postData.public_key = localKeys.publicKey;
		}

		return post("/user/poshLogin", {
			type: "application/x-www-form-urlencoded",
			data: postData,
		});
	}
);
const socialLoginService = createAsyncThunk(
	"auth/socialLoginService",
	async (values) => {
		if (!values) return;

		let localKeys = localStorage.getItem("keys");
		let postData = values;
		if (localKeys) {
			localKeys = JSON.parse(localKeys);
			postData.public_key = localKeys.publicKey;
		}

		return post("/user/social_login", {
			type: "application/x-www-form-urlencoded",
			data: postData,
		});
	}
);
const appleLoginService = createAsyncThunk(
	"auth/appleLoginService",
	async (values) => {
		if (!values) return;

		let localKeys = localStorage.getItem("keys");
		let postData = values;
		if (localKeys) {
			localKeys = JSON.parse(localKeys);
			postData.public_key = localKeys.publicKey;
		}

		return post("user/apple_login", {
			type: "application/x-www-form-urlencoded",
			data: postData,
		});
	}
);
export const { saveLoginResponse, resetLoginResponse } = loginSlice.actions;
export {
	loginService,
	signupService,
	loginwithPoshService,
	autoLoginwithPoshService,
	socialLoginService,
	appleLoginService,
};

export default loginSlice.reducer;
